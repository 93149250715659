export function useUserpilot() {
    function initUserPilot(userId, data = {}) {
        if (typeof window !== 'undefined' && window['userpilot']) {
            window['userpilot'].identify?.(userId, { ...data });
        }
    }

    function destroyUserPilot() {
        if (typeof window !== 'undefined' && window['userpilot']) {
            window['userpilot'].destroy?.();
        }
    }

    return {
        initUserPilot,
        destroyUserPilot,
    };
}
