import { alertError } from '@/common/utils/alert';
import { Bus } from '@/common/bus';
import { scrollToTop } from '@/common/utils/dom';
import { mixpanelTrackEvent } from '@/common/Composables/useMixpanel.js';
import { ErrorCodes } from '../Enums/errorCodes';

export const CPErrorHandler = (error, router, i18n) => {
    const { t } = i18n;

    const { response } = error;
    if (!response) {
        return;
    }
    mixpanelErrorEvent(response, router);

    switch (response.status) {
        case 503:
        case 520:
            alertError(
                "We couldn't complete your request at the moment, please try again."
            );
            Bus.$emit('form-modal:stop-saving');
            break;

        case 500:
            alertError(t('an_error_occurred'));
            break;

        case 504:
            alertError(t('can_not_serve'));
            break;

        case 429:
        case 401:
            router.replace({ name: 'logout' });
            break;

        case 403:
            if (response.data?.error_code === ErrorCodes.MOBILE_NOT_VERIFIED) {
                router.replace({
                    name: 'mobile-registration',
                });
            } else {
                alertError(t('not_authorized'));
            }
            break;

        case 404:
            router.replace({ path: '/404' });
            break;

        case 423: //locked exception
            alertError(t('try_again'));
            return;
    }
    scrollToTop();
};

const mixpanelErrorEvent = (response, router) => {
    const config = response?.config || {};
    const { url = '', method = '' } = config;
    const [baseUrl, query = ''] = url.split('?');
    mixpanelTrackEvent(
        'CP API Error',
        {
            code: response?.status || '',
            errorDescription: response?.data?.message || '',
            requestType: method,
            fullRequestUrl: url,
            requestUrl: baseUrl,
            requestQuery: query,
            errorCode: response?.headers?.error_code || '',
        },
        router.currentRoute.value
    );
};
