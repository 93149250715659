import { getEnumObject } from '../utilities.js';

export const deviceModels = {
    PAXS_900: 1,
    PAXD_200: 2,
    NEWLAND_SP_630: 3,
    NEWLAND_SP_930: 4,
    AMP_7000: 5,
    MOVE_2500: 6,
    SOFT_POS: 7,
    NEWLAND_N_910: 8,
    IMIN_M2_MAX: 9,
    IMIN_FI22: 10,
    MOVE_3500: 11,
    NEWLAND_X_800: 12,
    UROVO_I_9100: 13,
    PICO: 14,
};

export default getEnumObject(deviceModels);
