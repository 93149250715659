import { genders } from '@/common/Enums/genders.js';
import mccEnum from '@/common/Enums/mcc.js';
import { startCase } from 'lodash';

export function initGainsight(user) {
    if (typeof window.aptrinsic === 'undefined') {
        return;
    }

    // Destructure `user` and `merchant` properties, defaulting `merchant` to an empty object
    const {
        id,
        email,
        name,
        preferred_language,
        is_owner,
        gender,
        merchant: {
            business_reference = '',
            reference = '',
            business_name = '',
            mcc,
            created_at: merchant_created_at,
        } = {},
        created_at,
    } = user;

    const [firstName, lastName] = (name || '').split(' ');

    const userInfo = {
        id,
        email,
        username: name,
        firstName: firstName || '',
        lastName: lastName || '',
        PreferredLanguage: preferred_language,
        language: localStorage.getItem('locale') || 'en',
        is_owner,
        gender: gender === genders.MALE ? 'Male' : 'Female',
        F5Number: business_reference,
        MerchantReference: reference,
        CreatedAt: created_at ? new Date(created_at).getTime() : '',
    };

    const formattedMMC = mcc
        ? startCase(`${mccEnum[mcc] || ''}`.replaceAll(/[_-]/g, ' '))
        : '';

    const accountInfo = {
        id: business_reference || reference || '',
        name: business_name,
        F5Number: business_reference,
        MerchantReference: reference,
        MerchantReferenceID: reference,
        continent: 'As',
        countryCode: 'SA',
        countryName: 'Saudi Arabia',
        timeZone: '+03:00',
        BusinessCountry: 'SA',
        BusinessCreatedAt: merchant_created_at
            ? new Date(merchant_created_at).getTime()
            : '',
        MCC: formattedMMC,
    };
    window.aptrinsic('identify', userInfo, accountInfo);
}

export function resetGainsight() {
    if (typeof window.aptrinsic === 'undefined') {
        return;
    }
    window.aptrinsic('reset');
}
