import moment from 'moment-timezone';

// Function to get common headers used by both projects
function getCommonHeaders() {
    const tokenElement = document.head.querySelector('meta[name="csrf-token"]');
    const csrfToken = tokenElement ? tokenElement.content : '';
    const currentToken =
        sessionStorage.getItem('token') || localStorage.getItem('token');

    return {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': csrfToken,
        'Accept-Language': localStorage.getItem('locale') || 'en',
        Authorization: `Bearer ${currentToken}`,
    };
}

// Function to get headers specific to the MMS project
export function getMMSHeaders() {
    return {
        ...getCommonHeaders(),
        Timezone: moment.tz.guess(),
    };
}

// Function to get headers specific to the CP project
export function getCPHeaders() {
    return {
        ...getCommonHeaders(),
    };
}
