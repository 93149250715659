import { getEnumObject } from '../utilities.js';

export const paymentTypes = {
    SETTLEMENT: 1,
    TRANSFER_FEE: 2,
    MONTHLY_SUBSCRIPTION: 3,
    CAPITAL_LOANS_INSTALLMENTS: 4,
    TRANSFER_FUNDS: 5,
    RETURN_EMERGENCY_FUNDS: 6,
    FLEX: 7,
    SUPPLY_LOANS_INSTALLMENTS: 8,
};

export default getEnumObject(paymentTypes);
