import { getEnumObject } from '../utilities.js';

export const integrationSources = {
    ABSHER: 'ABSHER',
    WATHQ: 'WATHQ',
    YAKEEN: 'YAKEEN',
    ARNBSARI: 'ARNBSARI',
    RIBLSARI: 'RIBLSARI',
    INMASARI: 'INMASARI',
    SNB: 'NCBKSAJE',
    RAJHI: 'RJHISARI',
    FOCAL: 'FOCAL',
    SAMA: 'SAMA',
    MAMUN: 'MAMUN',
    CAPITAL: 'CAPITAL',
    SUPPLY: 'SUPPLY',
    INTERSOFT: 'INTERSOFT',
    CASHIER_APP: 'CASHIER_APP',
    NAFATH: 'NAFATH',
    NEAR_PAY: 'NEAR_PAY',
    NEAR_PAY_ACCOUNTS_API: 'NEAR_PAY_ACCOUNTS_API',
    WEBHOOKS: 'WEBHOOKS',
    ADMIN: 'ADMIN',
    RMS: 'RMS',
    OTHER: 'OTHER',
    CHECKOUT: 'CHECKOUT',
    CONSOLE: 'CONSOLE',
    NGO: 'NGO',
    ZATCA: 'ZATCA',
    KIOSK: 'KIOSK',
    PICO: 'PICO',
};

export default getEnumObject(integrationSources);
