import { merge } from 'lodash';
import enCommon from '@/common/I18n/en.js';
import customerInsights from './CustomerInsights/en.js';
import notFound from './NotFound/en.js';
import unauthorized from './Unauthorized/en.js';
import phoneVerfication from './MobileRegistration/en.js';

const translations = {
    ...enCommon,
    dashboard: {
        title: 'Dashboard',
        tabs: {
            day: 'Day',
            week: 'Week',
            month: 'Month',
        },
    },
    other_lang: 'ar',
    other_lang_long: 'عربي',
    terminals: 'Terminals',
    payments: 'Payouts',
    home: 'Home',
    account: 'Account',
    contact_us: 'Contact US',
    logout: 'Logout',
    total_transaction_amount: 'Total Transaction Amount',
    total_received_amount: 'Total Received Amount',
    terminal_id8: 'Terminal ID 8',
    total_terminals: 'Total Terminals',
    transaction_number: 'Transaction Number',
    active_terminals: 'Active Terminals',
    pending_activation: 'Pending Activation',
    enabled_card_schemes: 'Enabled Card Schemes',
    channel: 'Channel',
    transaction_amount: 'Transaction Amount',
    total_amount: 'Total Amount',
    settled_amount: 'Total Settled Amount',
    transaction_count: 'Transaction Count',
    date: 'Date',
    sar: 'SAR',
    month: 'Month',
    month_range: 'Month Range',
    total_fees: 'Total Fees',
    details: 'Details',
    monthly: 'Monthly',
    weekly: 'Weekly',
    daily: 'Daily',
    ticket_type: 'Ticket type',
    terminal_id: 'Terminal ID',
    name: 'Name',
    email: 'Email',
    mobile_number: 'Mobile number',
    message: 'Message',
    message_placeholder:
        'Please provide the details of the issue or the inquiry...',
    submit: 'Submit',
    done: 'Done',
    back: 'Back',
    log_in: 'Log in',
    password: 'Password',
    login_to_your_account: 'Log in to your {0} account',
    confirm_password: 'Confirm password',
    password_mismatch:
        'Your password confirmation must match the entered password',
    invalid_password:
        'The password must be a minimum of 10 characters with at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character',
    invalid_credentials: 'Your credentials are not valid',
    please_wait: 'Please wait...',
    login_unknown_error: 'An error has occurred during logging in',
    terminal_issue: 'Terminal issue',
    app_issue: 'App issue',
    subscription_issue: 'Subscription',
    inquiry: 'Inquiry',
    complaint: 'Complaint',
    suggestion: 'Suggestion',
    other: 'Other',
    cancel: 'Cancel',
    ok: 'OK',
    required_ticket_type: 'Ticket type is required',
    required_terminal_id: 'Terminal ID is required',
    invalid_terminal_id: 'The terminal ID must consist of 8 digits',
    required_name: 'Name is required',
    required_email: 'Email is required',
    required_owner_email: 'Owner Email is required',
    required_mobile: 'Mobile number is required',
    required_message: 'Message is required',
    invalid_email: 'Please enter a valid email',
    invalid_mobile: 'Please enter a valid mobile number',
    thank_you_for_contacting:
        'Thank you for contacting us. We will get back to you soon!',
    thank_you_for_registration: 'Thank you for registration!',
    could_not_submit_request:
        "We couldn't submit the request. Please try again.",
    sn: 'SN',
    total_transactions: 'Total Transactions',
    week: 'Week',
    merchant_name: 'Merchant Name',
    merchant_name_arabic: 'Merchant Name (Arabic)',
    city: 'City',
    branch_city: 'Branch City',
    address: 'Address',
    bank_name: 'Bank Name',
    beneficiary_name: 'Beneficiary Name',
    bank_beneficiary_name: 'Beneficiary Name',
    iban: 'IBAN',
    mada_fee: 'MADA Fee',
    credit_card_fee: 'Credit Card Fee',
    contact_number: 'Contact Number',
    search_terminal_placeholder: 'Search by terminal ID..',
    transactions: 'Transactions',
    last_reconciliation: 'Last reconciliation',
    retailer_id: 'Retailer ID',
    is_enabled: 'Is enabled?',
    unsettled_amount: 'Total Unsettled Amount',
    total_unsettled: 'Total Unsettled',
    yes_existing_customer: "Yes, I'm an Existing Customer",
    no_not_existing_customer: "No, I'm a New Customer",
    last_transaction_date: 'Last transaction',
    search_by_payment_placeholder: 'Search by payout reference..',
    reference_no: 'Reference',
    amount: 'Amount',
    statement: 'Statement',
    pending_amount: 'Pending Amount',
    registration: 'Registration',
    registration_existing: 'Registration',
    registration_new: 'Registration - new customer',
    register: 'Register',
    cr_number: 'Commercial Registration',
    required_confirm_password: 'Password confirmation is required',
    required_password: 'Password is required',
    placeholder_register_terminal_id:
        'Enter any terminal ID associated with your account',
    invalid_cr_no: 'Please enter a valid Commercial Registration Number',
    verification: 'User Verification',
    resend_verification_code: 'Resend verification code',
    verification_merchant: 'Merchant Verification',
    verification_email: 'Email Verification',
    verification_code_sent_to_email:
        'Code was sent successfully to your email address',
    verification_code_sent_to_owner_phone:
        'Code was sent successfully to the registered mobile number!',
    placeholder_verification_code: 'Enter code here...',
    failed_to_contact_server:
        'It seems there is a problem contacting the server. Please check your internet connectivity and try again.',
    dont_have_account: 'Unregistered? Register Your Business on Foodics Pay',
    have_account: 'Already have an account?',
    login_here: 'Log in here',
    out_of: 'out of',
    terminals_are: 'terminals are',
    active: 'Active',
    active_filter: 'Active',
    not_active: 'Not Active',
    disabled: 'Disabled',
    enabled: 'Enabled',
    loading_more_data: 'Loading more data...',
    volume: 'Volume',
    last_recon: 'Last recon.',
    data_load_completed: 'Data is fully loaded',
    click_to_refresh: 'Click to refresh',
    connectivity_error:
        'There seems to be a connectivity issue. Please make sure you are connected to the internet and try again.',
    export_to_excel: 'Export to Excel',
    forgot_your_password: 'Forgot your password?',
    click_here: 'Click Here',
    click_here_to_edit_your_data: 'Click here to edit your data',
    restore_password: 'Reset your password',
    no_data_found: 'No data is found',
    change_password: 'Change Password',
    current_password: 'Current Password',
    required_current_password: 'Current password is required',
    are_you_an_existing_foodics_pay_customer:
        'Are you an existing Foodics Pay customer?',
    national_id: 'National ID/Iqama',
    inactive: 'Inactive',
    invalid_national_id_or_iqama: 'Please enter a valid National ID/Iqama',
    invalid_national_id_or_iqama_numeric:
        'National ID/Iqama should contain numbers only',
    invalid_national_id_or_iqama_length:
        'National ID/Iqama should be 10 digits long',
    invalid_national_id_or_iqama_start_with:
        'National ID/Iqama should start with 1 or 2',
    required_national_id_or_iqama: 'National ID/Iqama is required',
    vat_statements: 'VAT Statements',
    vat_statement_information: 'VAT Statement Details',
    download_statement: 'Download Statement',
    all: 'All',
    mada: 'MADA',
    'visa/mastercard': 'VISA/MasterCard',
    amex: 'AMEX',
    other_cards: 'Other cards',
    tax_invoice: 'Tax Invoice',
    search_by_invoice_placeholder: 'Search by invoice ID..',
    search_transaction_placeholder: 'Search by transaction ID.. ',
    total_count: 'Total Count',
    card_type: 'Card Type',
    status: 'Status',
    transaction_sequential_number: 'Transaction Sequential Number',
    retailer_name: 'Retailer Name',
    masked_card_number: 'Masked Card Number',
    transaction_date: 'Transaction Date',
    message_type_description: 'Message Type Description',
    merchant_fee: 'Merchant Fee',
    vat_amount: 'Vat Amount',
    net_amount: 'Net Amount',
    authorization_code: 'Authorization Code',
    aggregator_bank: 'Aggregator Bank',
    approval_code: 'Approval Code',
    email_or_username: 'Email or Username',
    branches: 'Branches',
    search_branches: 'Search by branch..',
    change_email: 'Change Email',
    confirm_email: 'Confirm Email',
    invoice_no: 'Invoice number',
    year: 'Year',
    invoice: 'Invoice',
    branch_no: 'Branch number',
    is_active: 'Enabled',
    is_main: 'Main Branch',
    total_vat: 'Total VAT Amount',
    unreconciled_amount: 'Total Unreconciled Amount',
    otp_code: 'OTP code',
    owner_name: 'Owner Full Name',
    gender: 'Gender',
    birth_date: 'Birth date',
    contact_name: 'Contact Full Name',
    contact_national_id: 'Contact National ID/Iqama',
    contact_mobile_number: 'Contact Mobile Number',
    mcc: 'Retailer Group (MCC)',
    municipality_number: 'Municipality number',
    business_type: 'Business type',
    bank: 'Bank',
    account_title: 'Account Beneficiary Name',
    account_number: 'Account Number',
    account_iban: 'Account IBAN',
    branch_name: 'Branch Name',
    zip_code: 'Zip Code',
    branch_address: 'Branch Address in English',
    building: 'Building',
    po_box: 'PO box',
    required_owner_name: 'Owner Full Name is required',
    required_gender: 'Gender is required',
    required_birth_date: 'Birthdate is required',
    required_mcc: 'Retailer Group (MCC) is required',
    required_cr_number:
        'Commercial Registration/Freelance License Number is required',
    required_municipality_number: 'Required municipality number',
    required_business_type: 'Business Type is required',
    required_bank_id: 'Bank Name is required',
    required_account_title: 'Beneficiary Name is required',
    invalid_account_title: 'Invalid account title',
    required_account_number: 'Account Number is required',
    invalid_account_number: 'Please enter a valid Account Number',
    required_account_iban: 'IBAN is required',
    invalid_iban: 'Please enter a valid Saudi IBAN',
    step_identity_verification: 'Identity Verification',
    step_otp: 'OTP Verification',
    step_owner_contact_info: 'Personal Information',
    step_email_verification: 'Email Verification',
    step_set_password: 'Set Password',
    step_terms_and_conditions: 'Terms and Conditions',
    prev: 'Prev',
    next: 'Next',
    finish: 'Finish',
    create_new_password: 'Create a new password to complete the account set up',
    password_instructions: 'Password instructions:',
    at_least: 'At least ',
    one_lower_case: '1 lower case letter [a-z]',
    one_upper_case: '1 upper case letter [A-Z]',
    one_numeric_character: '1 numeric character [0-9]',
    one_special_character: '1 special character: {special_character}',
    password_must_be_at_least: 'Password must be at least',
    must_contain: 'Must contain',
    password_must_contain_special_character:
        '1 special character (e.g., {special_character})',
    password_minimum_length: '10 characters in length',
    reset_your_password: 'Set password',
    new_email: 'New email',
    new_password: 'New Password',
    required_confirm_email: 'Email confirmation is required',
    email_mismatch: 'Email must match its confirmation',
    verify: 'Verify',
    foodics_pay_registration: 'Foodics Pay Registration',
    company_information: 'Company Information',
    owner_information: 'Owner Information',
    bank_account_details: 'Bank Account',
    contact_information: 'Contact Information',
    required_merchant_name_arabic: 'Merchant name in Arabic is required',
    invalid_merchant_name_arabic: 'Name should contain Arabic letters',
    required_merchant_name: 'Merchant name is required',
    invalid_merchant_name: 'Name should contain English letters',
    invalid_zipcode: 'Invalid zipcode',
    required_city: 'Branch City is required',
    required_branch_address: 'Branch Address is required',
    required_cr_certificate: 'Please upload the CR certificate',
    cr_certificate: 'CR certificate',
    required_owner_mobile: "Owner's mobile number is required",
    invalid_owner_mobile: 'Invalid mobile number',
    owner_mobile: "Owner's mobile number",
    owner_national_id: "Owner's National ID/Iqama",
    required_owner_national_id:
        'Please enter the National ID/Iqama for the owner',
    required_bank: 'Please select a bank',
    required_beneficiary_name: 'Please enter the account beneficiary name',
    invalid_beneficiary_name:
        'Beneficiary name must contain only Arabic or English letters or numbers or spaces',
    required_iban: 'IBAN is required',
    required_iban_certificate:
        'Please provide a photocopy of the IBAN certificate',
    iban_certificate: 'IBAN certificate',
    contact_phone: 'Contact mobile number',
    required_contact_national_id: 'Contact National ID/Iqama is required',
    required_contact_phone: 'Contact mobile number is required',
    required_contact_name: 'Contact Full Name is required',
    male: 'Male',
    female: 'Female',
    invalid_birth_date: 'Invalid birth date (e.g. 1986-09-22)',
    my_tickets: 'My Tickets',
    ticket_submitted_successfully:
        'Your ticket has been submitted successfully',
    ticket_not_submitted: 'Oh no! Something went wrong please try again',
    new_ticket: 'New Ticket',
    filter: 'Filter',
    ticket_id: 'Ticket Number',
    ticket_details: 'Ticket Details',
    support_chat: 'Support Chat',
    send: 'Send',
    new_request: 'New request',
    submit_ticket: 'Submit Ticket',
    upload: 'Upload File',
    say_something: 'Say something here ...',
    apply: 'Apply',
    clear: 'Clear',
    ticket_status: 'Ticket Status',
    tickets: 'Tickets',
    foodics_address:
        'Imam Saudi Faisal Rd, Riyadh 13515, 2nd Floor, Riyadh Kingdom Of Saudi Arabia',
    ticket_submit_reply: 'Your reply has been sent successfully.',
    email_placeholder: 'Enter Email Address',
    password_placeholder: 'Enter Your Password',
    linkedin: 'Linkedin',
    twitter: 'Twitter',
    facebook: 'Facebook',
    instagram: 'Instagram',
    forget_password: 'Forgot Password?',
    enter_email_account:
        'Please provide your registered email address to receive a one-time secure link to reset your password.',
    are_you_an_existing: 'Are you an existing',
    foodics_pay: 'Foodics Pay',
    customer: 'Customer',
    '?': '?',
    enter_your_name: 'Enter your name',
    enter_your_email: 'Enter your email',
    enter_your_phone: 'Enter your mobile number',
    enter_your_cr: 'Enter commercial registration',
    verified_identity: 'We have already verified your identity.',
    verified_email: 'We have already verified your email.',
    password_already_set: 'You have already set your password.',
    owner_is_the_contact_person: 'Owner is the contact person',
    enter_full_name: 'Enter Full Name',
    select_birthday: 'Birthday',
    select_gender: 'Select gender',
    enter_national_id: 'National ID/Iqama',
    enter_mobile_number: 'Enter mobile number',
    enter_email_address: 'Enter email address',
    step_license_info: 'License Information',
    select_mcc: 'Select Retailer Group (MCC)',
    enter_cr: 'Enter commercial registration or freelance license number',
    enter_business_name: 'Enter Business Name in English',
    select_business_type: 'Select Business Type',
    business_has_a_f5_account: 'Business has Foodics Console Account (F5)',
    business_reference: 'Foodics Console Account Number (F5)',
    enter_business_reference: 'Enter F5 Account Number',
    enter_account_number: 'Enter Account Number',
    upload_cr: 'Upload CR Certificate / Freelance License',
    business_name: 'Business Name',
    bank_info_added_later:
        'Bank information could be added from your account later',
    enter_beneficiary_name: 'Enter Beneficiary Name',
    step_bank_info: 'Bank Account Information',
    bank_account_info: 'Bank Account Information',
    select_bank: 'Select Bank Name',
    enter_account_iban: 'Enter Account IBAN',
    upload_iban: 'Upload IBAN Document',
    step_branch_info: 'Branch Information',
    branch_info_added_later:
        'Branches information could be added from your account later',
    enter_branch_name: 'Enter Branch Name',
    select_city: 'Select City',
    enter_branch_address: 'Enter branch address in English',
    enter_zipcode: 'Enter Zip Code',
    number_of_terminals: 'Number of Terminals',
    enter_terminal_number: 'Enter number of terminals',
    upload_branch_photo: 'Upload branch photo',
    required_branch_name: 'Branch Name is required',
    required_address: 'Branch Address is required',
    required_zip_code: 'Zip Code is required',
    required_terminal_number: 'Number of Terminals is required',
    required_business_name: 'Business Name is required',
    read_and_agree_on: 'I have read and agree on',
    terms_and_conditions: 'Terms & Conditions',
    personal_data_processing_agreement: 'Personal Data Processing Agreement',
    privacy_policy: 'Privacy Policy',
    read_the_condition:
        'I have read the terms and conditions and I agree to them ',
    information_is_correct:
        'I certify that the information provided is true and correct',
    registration_successful: 'Your registration is successful',
    thanks_text: 'Thank you. We have sent you an email to ',
    activate_text:
        'Please click the link in that message to activate your account.',
    extra_text:
        'Note that your request will not be processed till you activate your account.',
    back_to_login: 'Back To Login',
    email_sent_successful: 'Email Sent Successfully',
    password_reset_request_sent: 'Password Reset Request Sent',
    password_reset_sent:
        'We have sent an email to {email} to reset your password. Please follow the steps and try logging in again.',
    password_reset_sent_note:
        'Note: If the email is not found, please check your spam folder or contact the customer support team and provide your registered email address.',
    please_enter_otp: 'Please enter the OTP sent to',
    enter_otp: 'Enter OTP code',
    required_otp: 'OTP code is required',
    invalid_otp: 'The entered OTP is incorrect.',
    required_business_reference: 'F5 Account Number is required',
    invalid_business_reference: 'Please enter a valid F5 Account Number',
    step: 'Step',
    of: 'of',
    invalid_crCertificate: 'Please upload the CR certificate',
    invalid_ibanEvidence: 'Please upload the IBAN document',
    invalid_branchPhotos: 'Please upload branch photos',
    allowed_extensions: 'Uploaded file should be in JPEG, JPG, PNG, or PDF',
    maximum_file_size: 'File size should not exceed 1MB',
    latin_characters: 'Only latin characters are allowed',
    contact_info: 'Contact Information',
    contact_email: 'Contact email address',
    owner_email: 'Owner Email Address',
    required_contact_email: 'Contact email is required',
    enter_new_password: 'Enter a new password below to reset your password',
    're-enter-new-password': 'Re-enter new password',
    current_email_placeholder: 'Enter Current Email',
    new_email_placeholder: 'Enter New Email',
    enter_current_password_placeholder: 'Enter Current Password',
    enter_new_password_placeholder: 'Enter New Password',
    enter_new_re_enter_password_placeholder: 'Re-enter New Password',
    password_register: 'Password',
    placeholder_password: 'Enter Password',
    placeholder_confirm_password: 'Enter confirm password',
    required_email_registration: 'Email address is required',
    account_verification: 'Account Verification',
    'resend-otp-code': 'Resend OTP code',
    'minutes-left': 'Minutes Left',
    'hours-left': 'Hours Left',
    please_read_the_condition:
        'Please read the terms and conditions in the link below:',
    foodics_terms_and_condition: 'Foodics terms and conditions',
    prefer_lang: 'Preferred Communication Language',
    english: 'English',
    arabic: 'Arabic',
    select_prefer_lang: 'Select preferred communication language',
    required_prefer_lang: 'Preferred Communication Language is required',
    invalid_branch_name: 'Please enter a valid branch name',
    invalid_branch_address: 'Please enter a valid branch address',
    set: 'Set',
    max_char: 'Maximum number of characters is 40 for business name',
    min_char: 'Minimum number of characters is 3 for business name',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    settled: 'Settled',
    unsettled: 'Unsettled',
    unreconciled: 'Unreconciled',
    settled_transactions: 'Settled Transactions',
    unsettled_transactions: 'Unsettled Transactions',
    unreconciled_transactions: 'Unreconciled Transactions',
    total_fees_amount: 'Total Fees Amount',
    card_schemes: 'Card Schemes',
    enable_card_scheme: 'Enable Card Schemes',
    payable_amount: 'Payable Amount',
    max_char_business_name:
        'Maximum number of characters is 40 for business name',
    min_char_business_name:
        'Minimum number of characters is 5 for business name',
    min_char_contact_us_message:
        'Minimum number of characters is 3 for message',
    account_status: 'Account Status',
    welcome_to_foodics_pay: 'Welcome to Foodics Pay!',
    go_to_dashboard: 'Go To Dashboard',
    thank_you: 'Thank you.',
    status_step_1:
        "Thank you for completing the Foodics Pay On-boarding. We have received your application, and it's under process.",
    status_step_2:
        'Your application is being reviewed and will be configured once verified. Verification process usually takes from 1 to 3 business days, if any further clarification is needed from our side, our team will contact you. ',
    status_step_3:
        'Your account is under configuration, we are working on configuring your account with the Bank. Verification process usually takes from 1 to 3 business days, if any further clarification is needed from our side, our team will contact you.',
    status_step_4:
        'Your application has been approved and activated. You should have received an SMS on the registered number. Please contact out team for support',
    status_not_validated:
        'Your application has not been validated for the following reason:',
    invalid_name: 'Please enter a valid name',
    max_char_name: 'Maximum number of characters is 40 for name field',
    min_char_name: 'Minimum number of characters is 2 for named field',
    max_char_account_beneficiary_name:
        'Maximum number of characters is 35 for account beneficiary name',
    min_char_account_beneficiary_name:
        'Minimum number of characters is 5 for account beneficiary name',
    max_char_account_number:
        'Maximum number of characters is 24 for account number',
    min_char_account_number:
        'Minimum number of characters is 10 for account number',
    max_char_branch_name: 'Maximum number of characters is 40 for branch name',
    min_char_branch_name: 'Minimum number of characters is 3 for branch name',
    account_number_length:
        'Account Number should be between 10 and 24 characters long',
    beneficiary_name_length:
        'Beneficiary name should be between 5 and 35 characters long',
    cp_beneficiary_name: 'Beneficiary Name',
    data_not_found: 'No data Found',
    branch: 'Branch',
    terminal: 'Terminal',
    from: 'From',
    to: 'To',
    verification_mobile: 'Mobile Verification',
    otp_code_sent_to_mobile:
        'Code was sent successfully to the registered mobile number',
    resend_otp_code: 'Re-send OTP code',
    search_ticket_number_placeholder: 'Search by Ticket Number',
    foodics_pay_customer_portal: 'Foodics Pay Customer Portal',
    yes_terminal_active: 'Yes, the terminal is active',
    no_terminal_active: 'No, the terminal is not active',
    number_of_branches: 'Number of Branches',
    required_branches_number: 'Required brunch number',
    payment_option: 'Payout Option',
    required_payment_option: 'Required payout option',
    min_num_terminals: 'Minimum number of terminals is 1',
    min_num_brunches: 'Minimum number of branches is 1',
    change_email_successfully:
        'Your email has been changed successfully, you will be logged out now. Please login with your new email.',
    change_password_successfully: 'Your password has been changed successfully',
    changed_successfully: 'Changed Successfully',
    transaction_take_time:
        'Transactions take up to 1 working day to reflect here. Only transactions settled in the last 12 months are available. If you want to view transactions before this, click on Archived Transactions.',
    foodics_online_registration: 'Foodics Online Registration',
    max_char_ticket_message:
        'Maximum number of characters is 2000 for ticket message',
    application_received: 'Application Received',
    application_validation: 'Application Validation',
    account_configuration: 'Account Configuration',
    account_approval: 'Account Approval',
    account_rejected: 'Account Rejected',
    enter_merchant_name_in_arabic: 'Enter Merchant name in arabic',
    enter_merchant_name_in_english: 'Enter Merchant name in English',
    enter_address: 'Enter Address',
    enter_number_of_branches: 'Enter number of branches',
    select_payment_option: 'Select payout option',
    'ticket-attachment': 'Ticket Attachment',
    attachment: 'Attachment',
    'required-documents':
        'Please have the following documents/information ready to be uploaded in one of the following format : JPEG, PNG, JPG or PDF files.',
    iban_document: 'IBAN Document',
    cr_freelance_license: 'CR/Freelance License',
    branch_photo: 'Branch Photos',
    using_foodics_pay: "I'm already using Foodics Pay payout terminals",
    clear_all: 'Clear All',
    filters: 'Filters',
    business_name_in_english: 'Business name have to be in English',
    business_name_in_arabic: 'Business name have to be in Arabic',
    address_in_english: 'Address has to be in English',
    address_in_arabic: 'Address has to be in Arabic',
    business_name_ar: 'Business Name in Arabic',
    enter_business_name_ar: 'Enter business name in Arabic',
    branch_address_ar: 'Branch Address in Arabic',
    enter_branch_address_ar: 'Enter branch address in Arabic',
    terminal_take_time:
        'Terminals data might take from 1 to 3 working days to reflect here',
    version: 'Version',
    required_login_email: 'Email is required',
    delete_branch_alert: 'Are you sure you want to delete this branch?',
    delete: 'Delete',
    add_branch: 'Add Branch',
    bank_account: 'Bank Account',
    select_bank_account: 'Select bank account',
    required_bank_account: 'Bank account is required',
    add_bank: 'Add Bank Account',
    delete_confirmation: 'Delete confirmation',
    edit_bank: 'Edit Bank',
    edit_branch: 'Edit Branch',
    delete_bank_alert: 'Are you sure you want to delete this bank?',
    delete_bank_account_alert:
        'Are you sure you want to delete this bank account?',
    required_mobile_dial_code: 'Mobile dial code is required',
    transactions_menu: 'Transactions',
    dashboard_menu: 'Dashboard',
    payments_menu: 'Payouts',
    vat_statements_menu: 'VAT Statements',
    vat_invoices_menu: 'VAT Invoices',
    account_statement_menu: 'Account Statement',
    branches_menu: 'Branches',
    manage_menu: 'Manage',
    users_menu: 'Users',
    statements_menu: 'Statements',
    terminal_recon_menu: 'Terminal Reconciliation',
    bank_accounts_menu: 'Bank Accounts',
    reports_menu: 'Reports',
    bank_reconciliation_report_menu: 'Bank Reconciliation',
    new: 'New',
    beta: 'Beta',
    terminal_id16: 'Terminal ID 16',
    card_scheme: 'Card Scheme',
    transaction_fees: 'Transaction Fees',
    currency: 'Currency',
    order_reference: 'Order Reference',
    integrated: 'Integrated',
    settleable: 'Settleable',
    not_settleable_reason: 'Not Settleable Reason',
    terminal_reference: 'Terminal Reference',
    payment_reference: 'Payout Reference',
    view_receipt: 'View Receipt',
    payment: 'Payout',
    reference: 'Reference',
    credit_bank: 'Credit Bank',
    bank_status_message: 'Bank Status Message',
    transfer_date: 'Transfer Date',
    debit_bank_short_code: 'Debit Bank Short Code',
    debit_account_number: 'Debit Account Number',
    debit_beneficiary_name: 'Debit Beneficiary Name',
    credit_bank_short_code: 'Credit Bank Short Code',
    bank_reference: 'Bank Reference',
    credit_account_number: 'Credit Account Number',
    credit_beneficiary_name: 'Credit Beneficiary Name',
    credit_beneficiary_address: 'Credit Beneficiary Address',
    external_reference: 'External Reference',
    return_date: 'Return Date',
    return_reason: 'Return Reason',
    transactions_count: 'Transactions Count',
    transactions_amount: 'Transactions Amount',
    transactions_net_amount: 'Transactions Net Amount',
    deductions_amount: 'Deduction Amount',
    payment_no_transactions: 'This payout has no transactions assigned yet',
    bank_fee: 'Bank Fee',
    foodics_fee: 'Foodics Fee',
    installed: 'Installed',
    pulled_out: 'Pulled out',
    merchant: 'Merchant',
    yes: 'Yes',
    no: 'No',
    sim_card_provider: 'Sim Card Provider',
    trsm: 'Terminal Serial No',
    installation_request_date: 'Installation Request Date',
    installation_date: 'Installation Date',
    pullout_request_date: 'Pull Out Request Date',
    pullout_date: 'Pull Out Date',
    last_reconciliation_date: 'Last Reconciliation Date',
    device_information: 'Device Information',
    serial_number: 'Serial Number',
    vendor: 'Vendor',
    model: 'Model',
    firmware_version: 'Firmware Version',
    manufacture_date: 'Manufacture Date',
    end_of_life_date: 'End of Life Date',
    merchant_information: 'Merchant Information',
    subscription: 'Subscription',
    device: 'Device',
    subscription_information: 'Subscription Information',
    contract_type: 'Contract Type',
    start_date: 'Start Date',
    end_date: 'End Date',
    terminals_menu: 'Terminals',
    new_customer_status_menu: 'Status',
    are_you_sure_you_want_to_disable_this:
        'Are you sure you want to disable this?',

    transaction_information: 'Transaction Information',
    showing: 'Showing',
    processed: 'Processed',
    failed: 'Failed',
    payment_information: 'Payout Information',
    terminal_information: 'Terminal Information',
    invalid_zib_code: 'Invalid zip code',
    are_you_sure_you_want_to_delete_this:
        'Are you sure you want to delete this?',
    invalid_terminal_counts: 'Terminal count should be between 1 and 100',
    invalid_luhn_check:
        'This field has failed the validation rule. Make sure you enter a correct value.',
    invalid_full_name:
        'Name should contain first and last names. Each name is 2 letters minimum and can not contain non-alphabetic characters.',
    add_btn: 'Add',
    update_btn: 'Update',
    min_age: 'Age should be at least 18 years old',
    required_terms_accepted:
        'Please indicate that you have read and agree to Foodics terms and conditions',
    required_data_processing_accepted:
        'Please indicate that you have read and agree to data processing agreement',
    required_privacy_policy_accepted:
        'Please indicate that you have read and agree to privacy policy',
    required_certify_accepted:
        'Please certify that the information provided is true and correct',
    created_at: 'Created At',
    f5_branch_id: 'F5 Branch ID',
    branch_information: 'Branch Information',
    under_review: 'Under Review',
    payment_transactions: 'Payout Transactions',
    owner_birthdate: 'Owner Birthday',
    owner_mobile_number: 'Owner Mobile Number',
    profile: 'Profile',
    accounts_menu: 'Accounts',
    confirm_new_password: 'Confirm New Password',
    current_email_address: 'Current Email Address',
    new_email_address: 'New Email Address',
    password_changed_successfully: 'Password is changed successfully',
    are_you_sure_change_password:
        'Are you sure you want to change your password ?',
    email_changed_successfully: 'Email address is changed successfully',
    are_you_sure_change_email:
        'Are you sure you want to change your email address ?',
    email_changed_success: 'Email changed successfully.',
    password_changed_success: 'Password changed successfully.',
    mada_card_fee: 'Mada Card Fee',
    total_payments: 'Total Payouts',
    total_net_amount: 'Total Net Amount',
    total_pending: 'Total Pending',
    total_new: 'Total New',
    total_submitted: 'Total Submitted',
    total_under_review: 'Total Under Review',
    count: 'Count',
    date_range: 'Date Range',
    transaction_date_range: 'Transaction Date Range',
    transactions_dashboard: 'Transactions',
    payments_dashboard: 'Payouts',
    existing_customer_registered_successfully:
        'Thank you. You registered successfully, you can log in now',
    otp_sent_successfully: 'A new OTP has been sent successfully.',
    country_of_birth: 'Country of Birth',
    required_country_of_birth: 'Country of birth is required',
    country_of_residence: 'Country of Residence',
    required_country_of_residence: 'Country of residence is required',
    source_of_income: 'Source of Income',
    required_source_of_income: 'Source of Income is required',
    profession: 'Profession',
    required_profession: 'Profession is required',
    download_report: 'Download Report',
    update_successful: 'Update Successful',
    rejected_thanks_text:
        'Thank you. Your information has been updated successfully.',
    rejected_activate_text:
        'You can return back to your account to track the status.',
    back_to_status_page: 'Back to Account',
    valid_business_name:
        'Name should not start with space, and latin characters, spaces, and digits are only allowed.',
    valid_vat_registration_number:
        'Please enter a valid VAT number. It must contain 15 digits and start and end with the number 3.',
    vat_registration_number_required: 'VAT Registration Number is required.',
    update_vat_registration_number:
        'VAT Registration Number has been updated successfully.',
    solo_id: 'Solo ID',
    account_or_cr_number: 'F5 Account or CR number',
    account_or_cr_number_placeholder: 'Enter F5 Account/CR Number',
    required_account_or_cr_number:
        'Required since email is linked to multiple merchants',
    an_error_occurred: 'Something went wrong!',
    not_authorized: 'You are not authorized to perform this action.',
    try_again: 'Please try again',
    owner_otp: 'Owner OTP',
    contact_otp: 'Contact OTP',
    total_fee: 'Total Fee',
    total_fees_and_vat: 'Total Fees and Vat',
    card_number: 'Card Number',
    original_transaction: 'Original Transaction',
    reversed_transaction: 'Reversed Transaction',
    is_reversed: 'Reversed',
    credit_account_iban: 'Credit Account Iban',
    reason: 'Reason',
    payment_deductions: 'Payout Deductions',
    can_not_serve: 'Sorry, we couldn’t serve your request',
    change_mobile_number: 'Change Mobile Number',
    mobile_number_changed_success:
        'Your mobile number has been changed successfully.',
    create_foodics_pay_account: 'Create Foodics Pay Account',
    start_the_process:
        'To make the registration process fast and smooth, make sure to have your commercial and financial documents handy in one of the following formats: JPEG, PNG, JPG, or PDF files.',
    here_are_the_prerequisites: 'Major prerequisites:',
    prerequisites_one:
        '1. An active Nafath account is required for identity verification.',
    prerequisites_two: '2. CR Certificate/Freelance License document.',
    prerequisites_three: "3. Bank's IBAN document.",
    owner_only_note: 'Note: Only the business owner can complete this process.',
    get_started: 'Get Started',
    nafath_verify_identity: 'Verify your identity with Nafath',
    note: 'Note: ',
    nafath_verification_note: 'You can complete this verification through',
    and_mobile_app: 'and mobile app',
    enter: 'Enter',
    and_click: 'and click',
    to_start_the_verification: 'to start the verification.',
    nafath_verification_instructions:
        "Enter owner's national ID/Iqama and click Next to start the verification.",
    nafath_otp_instructions:
        'You will be prompted with a number on your Nafath account to verify and you will find that',
    number_here: ' number here.',
    select_nafath_number: 'Please select this number on your Nafath account',
    do_not_have_nafath_app:
        "If you don't have the Nafath App, click here to open",
    nafath_website: 'Nafath website',
    identity_verified: 'Identity Verified!',
    identity_not_verified: 'Identity could not be verified!',
    check_your_id_try_again:
        'Please check your ID number and click Next to try again to complete the verification on your Nafath account.',
    personal_info_description:
        'Please verify the pre-filled information and provide further information to know you better.',
    email_verification_description_part1: 'We sent you an OTP Code to',
    email_verification_description_part2:
        'to verify your email address. Please enter the OTP here.',
    license_info_description:
        'We need your business information and a commercial registration certificate or a freelance license if you are a freelancer.',
    bank_info_description:
        'Your bank account information is required to connect with Foodics pay account to disburse payouts',
    branch_info_description:
        'Please fill information for all the branches of your business in sequence and provide the number of terminals required in each branch.',
    set_password_description:
        'Create a new password to complete the account set up',
    terms_and_conditions_description:
        'Please read the terms and conditions in the link below',
    new_user: 'Add User',
    last_login: 'Last logged in',
    enable_user: 'Enable User',
    disable_user: 'Disable User',
    enable_users: 'Enable Users',
    disable_users: 'Disable Users',
    users: 'Users',
    actions: 'Actions',
    user_added_successfully: 'User added successfully',
    user_enabled_successfully: 'User enabled successfully',
    user_disabled_successfully: 'User disabled successfully',
    users_enabled_successfully: 'Users enabled successfully',
    users_disabled_successfully: 'Users disabled successfully',
    enable_user_confirmation: 'Are you sure you want to enable this user?',
    disable_user_confirmation: 'Are you sure you want to disable this user?',
    enable_users_confirmation: 'Are you sure you want to enable these users?',
    disable_users_confirmation: 'Are you sure you want to disable these users?',
    onboarding_login_hint: 'You can log in here.',
    reset_password: 'Password reset',
    create_your_login: 'Create your login',
    role: 'Role',
    already_have_contact_paerson:
        'You already have a user selected as a contact person',
    will_replace_new_contact_person:
        'You will replace the contact person with this new user.',
    replace_contact_person: 'Do you want to replace this contact person?',
    set_as_contact_person: 'Set as a contact person',
    contact_person_set_successfully: 'New contact person set successfully',
    contact_person: 'Contact Person',
    owner: 'Owner',
    user: 'User',
    free_days: 'Free Days',
    monthly_fee: 'Monthly Fee',
    gpv_lower_limit: 'GPV Lower Limit',
    gpv_upper_limit: 'GPV Upper Limit',
    monthly_fee_vat_exclusive: 'Monthly Fee (VAT Exclusive)',
    card_transaction_rate: 'Card Transaction Rate',
    generate_vat_statement: 'Generate VAT Statement',
    generate_and_download: 'Generate and download',
    generate_and_export: 'Generate and export',
    select_statment_month: 'Select Statement Month',
    group_by_branch: 'Group by Branch',
    break_by_transaction: 'Break By Transaction',
    looking_for_last_month_statment: "Looking for last month's statement?",
    vat_statement_for_the_previous_month_is_available:
        'The VAT statement for the previous month will be ready for download starting',
    from_the_4th_of_every_month: 'from the 4th of each month.',
    if_you_are_here_before_then_you_will_not_be_able_to_download_the_statement:
        'If you’re visiting before this date, it won’t be available yet.',
    retrieve_vat_invoice: 'Generate VAT Invoice',
    select_invoice_month: 'Select invoice month ',
    select_branches: 'Select Branches',
    looking_for_last_monthly_invoice: 'Looking for last month’s invoice?',
    vat_invoice_for_previous_month:
        'The VAT invoice for the previous month will be available starting',
    not_able_to_export_invoice:
        'If you’re here earlier, the invoice cannot be exported yet.',
    vat_invoice_successfully_generated_and_emailed:
        'Data is being exported in the background and you will receive an email when the export is done.',
    data_being_exported: 'Data is being exported',
    branch_is_required: 'Branch is required',
    branch_reference: 'Branch Reference',
    per_terminal_vat: 'SAR (excl. VAT) / month per Terminal',
    subscription_fee: 'Subscription Fee',
    rate: 'Rate',
    subscription_fee_note:
        ' The subscription fee is waived for any month when the average monthly transactions per terminal in branch exceed 15,000 SAR.',
    required_month: 'Please select a month',
    generate_vat_invoice_within_2_years:
        'Please note that you can only generate VAT Invoices for the last 12 months.',
    generate_vat_statement_within_2_years:
        'Please note that you can only generate VAT statements for the last 12 months.',
    vat_statment_successfully_generated_and_emailed:
        'VAT Statement is being generated in the background and you will receive an email when the statement is ready.',
    to_download_nafath_app: 'To download naftah app',
    my_apps: 'My Apps',
    required_number_branches: 'Please enter the number of branches',
    invalid_branch_counts: 'Number of Branches should be between 1 and 250',
    required_number_employees: 'Please enter the number of employees',
    required_monthly_turn_over: 'Please select an option',
    invalid_freelanceCertificate: 'Please upload your Freelance License',
    invalid_aoaCertificate: 'Please upload your AoA (Articles of Association)',
    pay: 'Pay',
    console: 'Console',
    online: 'Online',
    step_create_account: 'Create Account',
    step_nafath_verification: 'Verify identity with Nafath',
    step_business_information: 'Business Information',
    verify_create_account: 'Verify and Create Account',
    ask_you_for: 'We will ask you now for:',
    nafath_account: 'An active Nafath account',
    cr_certificate_freelance: 'CR Certificate/Freelance License document',
    next_steps_to_order_terminals:
        'In the next steps, to order terminals, we need also your',
    bank_iban_document: "Bank's IBAN document.",
    only_business_owner_can_complete:
        'Only the business owner can complete this process.',
    already_have_pay_account_log_in_here:
        'Already have Pay Account? Log in here',
    use_ur_email_and_password_to_sign_in_to_foodics_pay:
        'You will use your email and password to access transactional data and reports on the Pay Portal.',
    want_to_ad: 'Want to add',
    more_that_one_license: 'more than one License Number? ',
    do_that_later: 'You can do that later in your Account Settings.',
    license_run_under: 'What license does your business run under?',
    have_cr_unn_license: 'I have CR/UUN License',
    have_freelance_license: 'I have Freelance License',
    commercial_registration_license_number:
        'Commercial Registration License Number',
    number_of_employees: 'Number of Employees',
    monthly_turnover: 'Monthly Turnover',
    article_of_association: 'Upload AoA (Articles of Association)',
    i_certify_that_provided_data_is_true_and_correct:
        'I certify that provided data is true and correct',
    freelance_license_number: 'Freelance License Number',
    upload_cr_certificate: 'Upload CR Certificate',
    upload_freelance_license: 'Upload Freelance License',
    could_not_download_your_data_from_nafath:
        "We couldn't retrieve your data from Nafath.",
    could_not_get_the_data:
        "We couldn't retrieve your data from Nafath. Please provide us with this short information below:",
    full_name: 'Full Name',
    date_of_birth: 'Date of Birth',
    more_than: 'More than',
    skip: 'Skip',
    nafath_verify_identity_description:
        'Please ensure that you have the Nafath app installed on your phone and that your Nafath account is ready before proceeding to the next step',
    skip_nafath_description:
        'Nafath verification is a government requirement and must be done by the merchant owner to complete the onboarding process. You can verify your identity with Natath later on during the onboarding or',
    retry_now: 'Retry Now',
    your_identity_not_verified:
        'Your identity has not been verified by Nafath.',
    click_here_to_verify_nafaht:
        'Nafath verification is mandated by the Saudi Central Bank (SAMA). {click_here} to verify now to prevent any service disruption.',
    step_product_details: 'Product details',
    step_assign_branches: 'Choose branches',
    step_assign_bank_account: 'Link bank accounts',
    cancel_order: 'Cancel order',
    continue_your_order: 'Continue your order',
    not_received_otp: "Didn't receive the code? Resend code in ",
    excl_vat: 'excl.VAT',
    declined_reason: 'Decline reason',
    current_view: 'Current View',
    change_default_bank: 'Change Account for This Branch',
    change_bank_account: 'Choose Bank Account',
    main: 'Main',
    settled_payment: 'Settled',
    payout_initiated: 'Payout Initiated',
    bank_information: 'Bank Information',
    from_bank: 'From Bank',
    to_bank: 'To Bank',
    from_beneficiary_name: 'From Beneficiary Name',
    to_beneficiary_name: 'To Beneficiary Name',
    from_account_number: 'From Account Number',
    to_account_number: 'To Account Number',
    from_iban: 'From IBAN',
    to_iban: 'To IBAN',
    // Onboarding Terminals Flows
    product_description: {
        status: 'Status: ',
        welcome_to_customer_portal:
            'Welcome to your Foodics Pay Customer Portal',
        choose_products: 'Choose products',
        select_product_types:
            'First, select product types. You can order multiple product types now or later – on',
        terminal_page: 'Terminals page.',
        product_details: 'Product details',
        no_monthly_fees: 'No Monthly Fees',
        standard_pos_terminal: 'Standard PoS Terminal',
        foodics_online: 'Foodics Online',
        foodics_one: 'Foodics One',
        customer_display_payments: 'Customer Display Payments',
    },

    order_summary: {
        multiple_branches: 'Multiple branches',
        confirm_and_order: 'Confirm and Order',
        change: 'Change',
        Total: 'Total',
        month: 'Month',
        payments_fees: 'payouts fees',
        not_completed_order:
            'Please return and fix the error or delete the product it if no longer interested.',
        please_rates_terms_conditions_1: 'I accept ',
        please_rates_terms_conditions_2: 'the rates, terms, and conditions ',
        please_rates_terms_conditions_3: 'for Foodics Online.',
        are_you_sure_you_want_to_delete: 'Are you sure you want to delete ',
        please_accept_terms_conditions:
            'Please accept the terms and conditions related to the Foodics Online Product',
        delete_payment_terminal_product_alert:
            'Are you sure you want to delete Payout Terminal?',
    },

    product_progress_bar: {
        product_details: 'Product details',
        choose_branches: 'Choose branches',
        link_bank_accounts: 'Link bank accounts',
    },
    onboarding_card_schemes: {
        MADA: 'Mada',
        VISA_CREDIT: 'Credit Card',
    },
    terminal_product_details: {
        start_your_order: 'Start your order',
        edit_your_order: 'Edit your order',
    },
    pos_terminal_product: {
        standard_pos_terminal: 'Standard PoS Terminal',
        pos_termianl_description:
            'This affordable, portable card reader allows you to accept various payout types, including chip & PIN or contactless.',
        card_payment_rates: 'Card payout rates',
        fixed_amount: 'Fix amount',
        add_to_your_order: 'Add to your order',
        excl_vat: 'excl.VAT',
    },
    foodics_online_product: {
        online_license: 'Online License',
        foodics_online_description_1:
            'Your most convenient online ordering ecosystem. Say goodbye to paying third-party commission fees, and enable easy self-ordering directly from your website, mobile application and QR menu.',
        foodics_online_description_2: 'Become the owner of',
        foodics_online_description_3: 'your online ordering platform,',
        foodics_online_description_4:
            'with your own website, mobile application and payout gateway, all at your fingertips to support your rising digital branch!',
        online_payment_rates: 'Online Payout Rates',
    },
    customer_display_product: {
        customer_display_description:
            'Elevate your customers checkout experience with a single 8-inch display that lets them confirm their orders and tap to pay.',
    },
    foodics_one_product: {
        foodics_one_description:
            'Run your business effortlessly using a single device with a built-in cashier, fast printer, and secure payout gateway.',
    },
    assign_branches: {
        assign_locations_to_your_terminals:
            'Assign locations to your terminals',

        no_branches_selected:
            "You don't have any branches, please create one to assign to the order.",
        create_new_branch: 'Create a new branch',
        back_to_product_details: 'Back to product details',
        save_branch_information: 'Save branch information',
        branch_address: 'Branch Address',
        delete_branch: 'Delete Branch',
        branches_business_locations:
            'Branches are your business locations where the payout terminals will be utilized.',
        provide_full_address: 'Provide full address with zip code and city',
        start_choosing_branches:
            'Branches are your business locations. Start by choosing what branches you want to order for.',
        assign_business_branches:
            'Branches are your business locations. Choose branches you want to order for and assign them the required number of terminals.',
        selected_total_terminal_1: 'You have selected to order a total of',
        selected_total_terminal_2: 'across your branches.',
        minimum_terminals_per_branch:
            'At least 1 terminal is required per branch.',
        maximum_terminals_per_branch:
            'Maximum of {maxValue} terminals allowed per branch.',
        this_branch_associated_with_other_products:
            'This branch is associated with other products, are you sure you want to delete it?',
        need_to_select_terminal:
            'You need to select at least 1 terminal to continue.',
        can_not_assign_POS_terminals_to_branch:
            'This branch has a Foodics one terminal assigned to it, you can not assign POS terminals to it.',
        can_not_assign_CDS_terminals_to_branch:
            'This branch has a Foodics one terminal assigned to it, you can not assign CDS to it.',
        can_not_assign_Foodics_one_terminals_to_branch:
            'This branch has other terminals assigned to it, you can not assign a Foodics one terminal to it.',
    },
    assign_bank_account: {
        assign_bank_accounts: 'Assign bank accounts',
        link_bank_description_1:
            'Your terminals are connected to a branch – and each branch has to be linked to bank account. Bank account is required to connect with Foodics Pay ',
        to_disburse_payments: 'to disburse payouts.',
        link_bank_description_2:
            'The charges for using the terminals will be deducted from your payouts every 10th of the month. We will not charge any fee before activating the terminals.',
        link_bank_description_3:
            'You can connect all branches and terminals to one bank account or add several bank accounts and link them to your branches.',
        bank_account_is_required:
            'Bank account is required to connect with Foodics Pay',
        you_dont_any_registered_bank:
            "You don't have any registered bank account",
        add_a_bank_account: 'Add a bank account',
        add_new_bank_account: 'Add new bank account',
        add_bank_account: 'Add bank account',
        edit_bank_account: 'Edit Bank Account',
        iban_number: 'IBAN Number',
        delete_bank_account: 'Delete bank account',
        save_bank_account_info: 'Save bank account information',
        save_bank_account: 'Save Bank Account',
        back_to_branch_details: 'Back to branch details',
        create_bank_account: 'Create new bank account',
        not_assign_bank_account_to_branches:
            'Please assign bank account to all branches.',
        all_branches_assign_to_bank_accounts:
            'All branches are assigned to bank accounts.',
        branches_want_link_with_this_account:
            'Branches you want to link with this account',
    },
    recieved_order: {
        we_recieved_your_order: 'We have received your order!',
        we_now_need: 'We now need',
        two_four_business_days: '2-4 business days',
        to_verify_the_data_you_provided: 'to verify the data you provided.',
        once_every_thing_is_in_order:
            'Once everything is in order, your account status will change to active and we will contact you to arrange installation details.',
        notify_by_email_1: 'You will be notified',
        notify_by_email_2: 'by e-mail',
        notify_by_email_3: 'about any changes.',
        forgot_about_something: 'Forgot about something?',
        you_can_order_more_terminals_manage_branches:
            'You can order more Terminals, manage your Branches or add more bank accounts once your initial order is approved.',
        do_you_want_to_delete_your_order:
            'Do you want to change or delete your order?',
        please_conact_customer_support:
            'Please contact our Customer Support team on 9200 28928 or using the chat below.',
        could_not_verify_account: 'We couldn’t verify your account!',
        ask_for_additional_info_documents:
            'Sometimes we need to ask you for additional information or documents. Check details from our team:',
        what_do_you_have_do: 'What do you have to do now?',
        send_missing_info_files:
            'Send us the missing information or files (according to the instructions above) to',
        send_missing_info_to_email:
            'You can also send them in response to the e-mail we sent you.',
    },
    // Onboarding Sub Merchant
    payment_terminal_product: {
        payment_terminal: 'Payment Terminal',
        payment_terminal_description:
            'Elevate your payout experience with a versatile terminal supporting secure transactions for businesses and consumers.',
    },
    onboarding_already_submitted:
        'This request has already been submitted. Please login to see the status of your request.',
    enabled_filter: 'Enabled',
    deductions_menu: 'Deductions',
    subscriptions_menu: 'Subscriptions',
    loans_menu: 'Loans',
    deductions: {
        title: 'Deductions',
        deductions: 'Deductions',
        deduction_information: 'Deduction Information',
        deduction_details: 'Deduction Details',
        sub_deduction: 'Sub-Deduction: {count}',
        all_deductions: 'All Deductions',
        total_amount: 'Total Amount',
        total_applied_amount: 'Total Applied Amount',
        total_remaining_amount: 'Total Remaining Amount',
        created_at: 'Created at',
        export_pay_subscription: 'Export Pay Subscription',
        export_Compensation: 'Export Compensation',
        export_deduction: 'Export Deduction',
        export_rms_subscription: 'Export RMS Subscription',
        eligible_at: 'Eligible at',
        applied_payment: 'Applied Payout',
        remaining_amount: 'Remaining Amount',
        applied_amount: 'Applied Amount',
        applied_at: 'Applied at',
        confirmed_at: 'Confirmed at',
        terminal_id8: 'Terminal ID 8',
        type: 'Type',
        not_applied: 'Not Applied',
        sub_deductions: 'Sub-Deductions',
        applied_payout: 'Applied Payout',
        deduction_type: {
            pay_subscription: 'Pay Subscription',
            compensation: 'Compensation',
            deduction: 'Deduction',
            rms_subscription: 'RMS Subscription',
            supply_loan: 'Supply Loan',
            loan: 'Loan',
        },
        deduction_status: {
            new: 'NEW',
            applied: 'APPLIED',
            cancelled: 'CANCELLED',
            linked: 'LINKED',
            partially_applied: 'PARTIALLY APPLIED',
        },
        loans: {
            all: 'All Loans',
            all_supply_loan: 'All Supply Loans',
            header: 'Loans',
            title: 'Loan',
            supply_loan: 'Supply Loan',
            total: 'Total',
            monthly_installment: 'Monthly Installment',
            duration_in_months: 'Duration',
            export: 'Export Loan',
        },
        subscriptions: {
            header: 'Subscriptions',
            rms: 'RMS Subscriptions',
            pay: 'Pay Subscription',
            all: 'All Subscriptions',
            all_pay: 'All Pay Subscriptions',
            all_rms: 'All RMS Subscriptions',
        },
    },
    migrated: 'Migrated',
    data_is_being_exported:
        'Data is being exported in the background and you will receive an email when the export is done.',
    select_month: 'Select Month',
    select_duration: 'Select Duration',
    archived_transactions: 'Archived Transactions',
    transactions_archived: 'Transactions Archived',
    original_payment: 'Original Payout',
    payment_link: 'Payout Link',
    regenerated_payment: 'Regenerated Payout',
    regenerated_payment_link: 'Regenerated Payout Link',
    return_to_login: 'Return to login',
    resend_code_in: 'Resend code in',
    enter_verification_code: 'Enter your 4-digit verification code',
    account_locked: 'Your account is locked. Please contact customer support.',
    enter_verification_code_sent_to_email:
        'Please enter the 4-digit code that has been sent to your email {0}.',
    otp_verify: 'Verify',
    verification_code: 'OTP Code',
    resend_otp_failure:
        "Something went wrong. If the issue persist, please contact customer support on 9200 28928 or via email on pay.ops{'@'}foodics.com",
    nearpay_registration: {
        review_details: 'Review your details',
        thanks_for_interest: 'Thank you for your interest in Tap to Pay',
        account_created_success: 'Your account has been created successfully.',
        request_approval_soon:
            'our request will be approved soon, after which you will be able to log in and use the service.',
    },
    refund_transaction: {
        refund_transaction: 'Refund Transaction',
        refund: 'Refund',
        refund_an_amount_of: 'Refund an amount of ',
        refund_requested_successfully: 'Refund requested successfully',
        refund_has_been_requested: 'A refund has been requested',
        transaction_refunded: 'This transaction has already been refunded',
        transaction_older_than_30_days:
            'This transaction is more than 30 days old. Please contact your bank to issue a refund.',
        refund_not_allowed:
            'Refund not allowed, please contact your account manager',
        transaction_not_found:
            'Transaction not found, please contact your account manager',
        refund_error: 'Error, please contact your account manager',
        transaction_pending_capture:
            'The transaction amount has not been captured yet. Please check back later.',
    },
    maximum_date_range: 'Duration cannot exceed {maxDays} days',
    required_duration: 'Please select a duration',
    terminal_Reconciliation: 'Terminal Reconciliation',
    recon_date: 'Recon date',
    mada_total_amount: 'Mada Total Amount',
    visa_total_amount: 'Visa Total Amount',
    mastercard_total_amount: 'Mastercard Total Amount',
    terminal_recon_details: 'Terminal Reconciliation Details',
    terminal_recon_amount: 'Terminal Reconciliation Amount',
    card_scheme_name: 'Card Scheme Name',
    host_total_db_count: 'Host Total DB Count',
    host_total_cr_count: 'Host Total CR Count',
    host_total_db_amount: 'Host Total DB Amount',
    host_total_cr_amount: 'Host Total CR Amount',
    pos_total_db_count: 'POS Total DB Count',
    pos_total_cr_count: 'POS Total CR Count',
    pos_total_db_amount: 'POS Total DB Amount',
    pos_total_cr_amount: 'POS Total CR Amount',
    learn_more: 'Learn More',
    refund_amount: 'Please specify the amount',
    min_refund_amount: 'The amount must be above 0',
    max_refund_amount: 'The amount cannot exceed {maxAmount}',
    unreconciled_terminals: {
        unreconciled_terminals: 'Unreconciled Terminals',
        reconcile_now: 'Reconcile Now!',
        terminals_unreconciled_for_24_hours_reconcile_to_settle:
            'You have terminals that have not been reconciled for 24 hours or more. Please review and reconcile them promptly to settle them on time',
        do_not_remind_me_again: 'Don’t remind me again',
        view_x_unreconciled_terminals:
            'View ({unreconciled_terminals_count}) Unreconciled Terminals',
        contact_your_branch_to_reconcile:
            'Contact your branch to reconcile your terminals',
        terminals_unreconciled_for_24_hours_reconcile:
            'You have terminals that have not been reconciled for 24 hours or more. Please reconcile them promptly',
        unreconciled: 'Unreconciled',
    },
    cp_transaction_status_tooltip: {
        pending_reconciliation:
            'Transactions awaiting reconciliation before processing can begin. This typically involves physical reconciliation of terminals at the branch. Once reconciliation is complete, transactions will move into the processing stage.',
        processing:
            'Transactions currently being processed. This includes all initial steps from the moment the transaction is made, up to the point where it is ready for payout initiation. This includes checks for potential issues and ensuring all necessary details are verified. Settling time varies but typically moves forward within a business day.',
        payment_initiated:
            'The request to transfer funds to your account has been made to the bank. The transaction is in the final stages before the funds are deposited into your account. The timing for completion depends on bank processing, but payouts are generally made promptly.',
        settled:
            'Settlement has been fully completed, and the funds have been deposited into your account.',
    },
    export_transactions: {
        primary_action: 'Export Transactions',
        secondary_action: 'Export Transactions (Include Declined)',
    },
    merchant_reference: 'Foodics Pay Account Reference',
    generate_account_statement: 'Request Account Statement',
    grouped_by_interval: 'Grouped by interval',
    looking_for_account_statement: 'Looking for an account statement?',
    account_statement_for_payment_provides:
        'An account statement for payments provides a concise summary of payment amounts, organized by daily or monthly intervals, facilitating easy tracking of financial payments over time',
    view_x_ineligible_CRs: 'View ({x}) ineligible CRs',
    already_registered_cr_numbers: 'Already Registered CR Numbers',
    expired_cr_numbers: 'Expired CR Numbers',
    unauthorized_to_register: 'Unauthorized to Register',
    registered_by: 'Registered By',
    expiry_date: 'Expiry Date',
    registered: 'Registered',
    enter_details_manually: 'Enter Details Manually',
    are_you_a_freelancer: 'Are you a freelancer?',
    return_to_select_eligible_cr: 'Return to Select eligible CR',
    no_eligible_c_rs_available_for_selection:
        'No eligible CRs available for selection',
    freelance_license_details: 'Freelance License Details',
    other_commercial_registrations: 'Other Commercial Registrations',
    view_in_foodics_console: 'View in Foodics Console',
    maintenance_request: 'Maintenance Request',
    replacement_request: 'Replacement Request',
    paper_roll_request: 'Paper Roll Request',
    confirm_phone_number_message:
        'Please confirm your phone number to proceed.',
    submit_request: 'Submit Request',
    request_received: 'Request Received',
    request_failed: 'Request Failed',
    request_received_message: 'We have received your request!',
    error: 'Error!',
    representatives_contact_message:
        'Our representatives will be in touch with you within 1-2 business days.',
    request_failed_message:
        'Request failed, please try again later. If the issue persists, please contact us at',
    ticket_already_open:
        "It looks like a ticket is already open for this terminal. If you haven't received a response within the last 2 business days, please contact us at",
    switch_account: 'Switch Account',
    my_account: {
        title: 'Account Details',
        label: 'My Account',
        business_details: {
            title: 'Business Details',
            national_id: 'Owner National ID',
            business_name: 'Business Name',
            merchant_reference: 'Foodics Pay Account Reference',
            business_reference: 'Foodics Console Account Number (F5)',
            owner_name: 'Owner Full Name',
            freelance_license: 'Freelance License',
            commercial_registration_license: 'Commercial Registration',
            unn_number: 'UNN Number',
            vat_registration_number: 'VAT Registration Number',
            change_business_name_info:
                'Changing the business name will update how your name appears in customer SMS notifications and on their bank statements when they make purchases from you.',
            business_name_min_char:
                'Minimum number of characters is 5 for business name',
            required_business_name: 'Business Name is required',
        },
        national_address: {
            title: 'National Address',
            street_name: 'Street Name',
            street_name_localized: 'Street Name (Arabic)',
            building_number: 'Building Number',
            district: 'District',
            district_localized: 'District (Arabic)',
            city: 'City',
            city_localized: 'City (Arabic)',
            postal_code: 'Postal Code',
            update_national_address: 'Update National Address',
            national_address_updated_success:
                'National Address Updated Successfully.',
            required_street_name: 'Street Name is required',
            required_building_number: 'Building Number is required',
            required_district: 'District is required',
            required_city: 'City is required',
            required_postal_code: 'Postal Code is required',
            invalid_postal_code: 'Postal Code should contain numbers only',
            invalid_building_number:
                'Building Number should contain numbers only',
        },
        profile: {
            title: 'My Profile',
            name: 'Name',
            email: 'Email Address',
            mobile_number: 'Mobile number',
        },
    },
    reviewing_information:
        "We're currently reviewing the information, which usually takes one business day. We'll notify you as soon as the review is complete.",
    bank_accounts: {
        title: 'Bank Accounts',
        approved: 'Approved',
        pending_review: 'Pending Review',
        rejected: 'Rejected',
        add_bank_account: 'Add Bank Account',
        linked_branches: 'Linked Branches',
        validate_iban: 'Validating the IBAN',
        update_bank_account: 'Update Bank Account',
    },
    pending_reconciliation: 'Pending Reconciliation',
    pending_capture: 'Pending Capture',
    processing: 'Processing',
    copy_value: 'Copy Value',
    page_title: {
        my_account: 'Account Details',
        dashboard: 'Dashboard',
        payouts_list: 'Payouts',
        payout_details: 'Payout Details',
        transactions_list: 'Transactions',
        transaction_details: 'Transaction Details',
        vat_statements: 'VAT Statements',
        vat_invoices: 'VAT Invoices',
        account_statement: 'Account Statement',
        subscriptions_list: 'Subscriptions',
        rms_subscription_details: 'RMS Subscription Details',
        loans_list: 'Loans',
        loan_details: 'Loan Details',
        supply_loan_details: 'Supply Loan Details',
        deduction_details: 'Deduction Details',
        bank_accounts_list: 'Bank Accounts',
        branches_list: 'Branches',
        branch_details: 'Branch Details',
        terminals_list: 'Terminals',
        terminal_details: 'Terminal Details',
        users_list: 'Users',
        terminal_recon_list: 'Terminal Reconciliation',
        terminal_recon_details: 'Terminal Reconciliation Details',
        login: 'Log In',
        otp: 'OTP',
        onboarding: 'Foodics Pay Onboarding',
        nearpay_registration: 'iPhone Tap to Pay Onboarding',
        forget_password: 'Forgot Password?',
        logout: 'Log out',
        verify_nafath: 'Nafath Verification',
        impersonation: 'Impersonation',
        reset_password: 'Reset Password',
        bank_reconciliation_report: 'Bank Reconciliation Report',
    },
    change_branch_name: {
        different_branch_name_in_RMS:
            'We detected a different name for this branch in Foodics Console',
        click_to_review: 'Click here to review',
        hide_this_alert: 'Hide this alert',
        name_in_console: 'Name in Console: ',
        use_as_pay_branch_name: 'Use as Pay Branch Name',
        branch_name_updated: 'Branch Name has been updated.',
    },
    bank_reconciliation: {
        title: 'Bank Reconciliation Report',
        export: 'Report',
        daily_breakdown: 'Daily Breakdown',
        day: 'Day',
        transactions: 'Transactions',
        fees_vat: 'Fees + VAT',
        net_amount: 'Net Amount',
        adjustments: 'Adjustments',
        payouts_initiated: 'Payouts Initiated',
        payouts_settled: 'Payouts Settled',
        outstanding: 'Outstanding',
        total: 'Total',
    },
    active_pending: 'Active or Pending Activation',
    in_review: 'In Review',
    vat_registration_number: 'VAT Registration Number',
    confirm_vat_registration_number:
        'Please confirm your VAT Registration Number to proceed.',
};

export default merge(
    translations,
    customerInsights,
    unauthorized,
    notFound,
    phoneVerfication
);
