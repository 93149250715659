import axios from 'axios';
import qs from 'qs';
import { flatten } from 'lodash';
import { useHttp } from '@/common/Composables/useNewHttp.js';

export function useFetchAll() {
    const fetchItemsParallel = async (lastResponse, requestData, uri) => {
        const http = useHttp();

        if (lastResponse.meta.last_page === 1) {
            return lastResponse.data;
        }

        const pagesCount = lastResponse.meta.last_page - 1;

        const requests = Array.from({ length: pagesCount }, (_, index) => {
            const query = qs.stringify({ ...requestData, page: index + 2 });
            return http.get(`${uri}?${query}`);
        });

        const responses = await axios.all(requests);

        const additionalData = flatten(
            responses.map((response) => response.data.data)
        );
        return [...lastResponse.data, ...additionalData];
    };

    const fetchAll = async (requestsData) => {
        const http = useHttp();

        const initialRequests = requestsData.map((requestData) => {
            const query = qs.stringify(requestData);
            const fullUrl = `${requestData.url}?${query}`;
            return http.get(fullUrl);
        });

        const responses = await axios.all(initialRequests);

        const results = await Promise.all(
            responses.map((response, index) =>
                fetchItemsParallel(
                    response.data,
                    requestsData[index],
                    requestsData[index].url
                )
            )
        );

        return results;
    };

    return { fetchAll, fetchItemsParallel };
}
