<script>
import { camelCase } from 'lodash';
import FdxCopyToClipboard from './FdxCopyToClipboard.vue';

export default {
    components: {
        FdxCopyToClipboard,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number, Object],
            default: '',
        },
        hasCustomValue: {
            type: Boolean,
        },
        single: {
            type: Boolean,
        },
        appliedClasses: {
            type: String,
            default: '',
        },
        truncate: {
            type: Boolean,
            default: false,
        },
        slotCssClass: {
            type: String,
            default: 'font-semibold',
        },
        slotValueCssClass: {
            type: String,
            default: '',
        },
        showIcon: {
            type: Boolean,
        },
        icon: {
            type: String,
            default: 'external-link',
        },
        iconClasses: {
            type: String,
            default: '',
        },
        excludeValueHover: {
            type: Boolean,
        },
        hoverMessage: {
            type: String,
            default: '',
        },
        showCopyValue: {
            type: Boolean,
        },
        tooltipIcon: {
            type: String,
            default: 'information-outline',
        },
        tooltipIconClasses: {
            type: [String, Array],
            default: 'size-4',
        },
        tooltipPosition: {
            type: String,
            default: 'right',
        },
    },
    emits: ['extra-click'],
    computed: {
        formattedValue() {
            if (this.hasNoValue()) {
                return '—';
            }
            return this.value;
        },
        shouldShowCopyIcon() {
            return this.showCopyValue && !this.hasNoValue();
        },
    },
    methods: {
        onClick() {
            if (this.hasNoValue()) {
                return;
            }
            this.$emit('extra-click');
        },
        hasNoValue() {
            return (
                this.value === undefined ||
                this.value === null ||
                this.value === ''
            );
        },
        camelCase,
    },
};
</script>

<template>
    <div
        class="details-card-item w-full pb-4 mb-4 border-b border-gray-400"
        :class="{ 'lg:w-1/2': !single }">
        <div
            :id="title"
            :data-testid="camelCase(title)"
            class="details-card grid grid-cols-[1fr_1fr] gap-2"
            :class="appliedClasses">
            <div class="text-gray-800 text-callout">
                {{ $t(title) }}
            </div>

            <div class="flex w-full max-w-full overflow-hidden">
                <div
                    :class="[
                        slotCssClass +
                            (truncate ? ' truncate' : ' line-clamp-4'),
                        {
                            'flex items-center gap-2': showIcon || hoverMessage,
                        },
                        'text-body font-bold pe-2',
                    ]"
                    @click="onClick()">
                    <slot>
                        <span v-if="hasCustomValue" :class="slotValueCssClass">
                            <slot name="extraValues" />
                            <svg-icon
                                v-if="formattedValue?.toLowerCase?.() === 'sar'"
                                icon="riyal"
                                class="w-4 h-4" />
                            <span v-else>{{ formattedValue }}</span>
                        </span>
                        <span
                            v-else-if="showIcon"
                            :title="formattedValue"
                            :class="slotValueCssClass">
                            <div
                                :class="[
                                    'items-center gap-1 cursor-pointer inline-flex fill-[#3e3e3e]',
                                    {
                                        'hover:fill-primary hover:text-primary':
                                            !excludeValueHover,
                                    },
                                ]">
                                <span class="bidirectional-auto-calculation">
                                    <div
                                        class="flex items-center gap-2 rtl:flex-row-reverse rtl:justify-end rtl:me-2">
                                        <svg-icon
                                            v-if="
                                                formattedValue?.toLowerCase?.() ===
                                                'sar'
                                            "
                                            icon="riyal"
                                            class="w-4 h-4" />
                                        <span v-else>{{ formattedValue }}</span>
                                    </div>
                                </span>
                                <span class="flex">
                                    <svg-icon
                                        class="svg-icon"
                                        :classes="iconClasses"
                                        :icon />
                                </span>
                            </div>
                        </span>
                        <span
                            v-else
                            :title="formattedValue"
                            class="bidirectional-auto-calculation"
                            :class="slotValueCssClass">
                            <span
                                v-if="
                                    formattedValue?.toLowerCase?.() === 'sar'
                                ">
                                <svg-icon icon="riyal" class="w-4 h-4" />
                            </span>
                            <span v-else>
                                {{ formattedValue }}
                            </span>
                        </span>
                    </slot>
                    <new-tooltip
                        v-if="hoverMessage"
                        has-icon
                        :icon-classes="tooltipIconClasses"
                        :icon-name="tooltipIcon"
                        :position="tooltipPosition"
                        not-full-width
                        :help-text="$t(hoverMessage)">
                    </new-tooltip>
                </div>

                <div v-if="shouldShowCopyIcon" class="flex-shrink-0 mr-4">
                    <FdxCopyToClipboard :value="formattedValue" />
                </div>
            </div>
            <slot name="additionalInfo"></slot>
        </div>
    </div>
</template>

<style scoped>
.bidirectional-auto-calculation {
    unicode-bidi: plaintext;
}
</style>
