import axios from 'axios';

let httpInstance = null;
let config = {};

/**
 * @param {Object} options
 * @param {string} options.baseURL
 * @param {Function} options.errorHandler
 * @param {Object} options.router
 * @param {Function} options.headers
 */
export function setupHttp(options) {
    config = options;

    if (httpInstance) {
        httpInstance = createHttp();
    }
}

function createHttp() {
    const instance = axios.create({
        baseURL: config.baseURL || '',
    });

    instance.interceptors.request.use((requestConfig) => {
        requestConfig.headers = {
            ...requestConfig.headers,
            ...config.headers(),
        };
        return requestConfig;
    });

    instance.interceptors.response.use(
        (response) => {
            if (
                response.headers['x-build'] &&
                response.headers['x-build'] !== window.foodics?.config.build
            ) {
                location.reload();
            }

            if (
                response.headers['x-trial-expired'] &&
                response.headers['x-trial-expired'] == 1 &&
                config.router.currentRoute.value.name !== 'trialExpired'
            ) {
                config.router.replace({ path: '/trial-expired' });
            }

            return response;
        },
        (error) => {
            if (error.config?.hasCustomErrorHandling) {
                return Promise.reject(error);
            }
            config.errorHandler?.(error, config.router);

            return Promise.reject(error);
        }
    );

    return instance;
}

export function useHttp() {
    if (!httpInstance) {
        httpInstance = createHttp();
    }
    return httpInstance;
}
