<script>
import {
    pick,
    omitBy,
    cloneDeep,
    find,
    isEqual,
    isEmpty,
    clone,
    omit,
    map,
    flatten,
    forOwn,
    pull,
    includes,
    isNull,
    isUndefined,
    camelCase,
} from 'lodash';
import qs from 'qs';
import NProgress from 'nprogress';
import { parseObject } from '@/common/Utilities/queryTypes.js';
import { isObjectEmpty } from '../utils/object';
import { useMixpanel } from '@/common/Composables/useMixpanel.js';
import { roundDownToNearestThousand } from '../utils/number';
import { Bus } from '../bus';
import FdxScrollFade from '@/common/Components/FdxScrollFade.vue';
import { useHttp } from '../Composables/useNewHttp.js';
import FdxIntersectionTrigger from './FdxIntersectionTrigger.vue';

export default {
    components: {
        FdxScrollFade,
        FdxIntersectionTrigger,
    },
    props: {
        tabs: { type: Array, default: () => [] },
        columns: { type: Array, default: () => [] },
        title: {
            type: String,
            default: '',
        },
        noRecordsText: { type: String, default: 'No data to display!' },
        requestUrl: { type: String, required: true },
        requestType: { type: String, default: 'get' },
        requestIncludes: { type: Array, default: () => [] },
        dataFilter: { type: Function, default: null },
        // ::TODO Refactor the all types filters
        /*
         * these filters are sent with the fetching request as long as they are not overrided from the parent component
         * and not passed to the url as query params and not sent with the export action
         */
        defaultFilters: { type: Object, default: () => ({}) },
        /*
         * these filters are used to replace the query params filters if no query params filters are passed
         * and you should pass it to the export-import-actions component to be sent to the export action
         */
        hiddenFilters: {
            type: Object,
            default() {
                return {};
            },
        },
        /*
         * disableBulkActions is used to disable the bulk actions
         * if it's a function, it will be called with the items as argument
         */
        disableBulkActions: {
            type: [Boolean, Function],
            default: true,
        },
        showRefreshButton: {
            type: Boolean,
            default: true,
        },
        enableDeletedAtFilter: {
            type: Boolean,
            default: true,
        },
        supplementRequestData: {
            type: Object,
            default() {
                return {};
            },
        },
        isRowClickable: {
            type: Boolean,
            default: true,
        },
        noWrap: {
            type: Boolean,
        },
        isFilterInHeader: {
            type: Boolean,
            default: true,
        },
        hasFilters: {
            type: Boolean,
            default: true,
        },
        filtersCallback: {
            type: Function,
            default: (filters) => filters,
        },
        breadcrumbHref: {
            type: String,
            default: '',
        },
        forceBreadcrumbHref: {
            type: Boolean,
            default: true,
        },
        listingStyle: {
            type: String,
            default: 'table',
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        tableClasses: {
            type: String,
            default: 'card',
        },
        itemsFilter: {
            type: Function,
            default(items) {
                return items;
            },
        },
        perPage: {
            // when this is passed as -1, it will signal that per page is unknown (ex: inventory level report)
            type: Number,
            default: 50,
        },
        cardsColumns: {
            type: String,
            default: '3',
        },
        filtersRequired: {
            // when true, this component will NOT load the items initially, rather it will open the filters modal to allow user to filter first then fetch, used in reports where huge data is expected, ex: inventory history report
            type: Boolean,
        },
        errorCallback: {
            // a callback that is called when a fetching error occur, the error object is passd to this callback.
            type: Function,
            default: null,
        },
        clearFiltersCallback: {
            // callback that can be used where there is a required filter, filters will be set to whatever this CB returns;
            type: Function,
            default() {
                return {};
            },
        },
        externalFiltersTransform: {
            // an async callback that can be used to transfor filters, ex: selecting branches by tags you'd need to transfor branch_tag_id to branch_id
            type: Function,
            default(filters) {
                return filters;
            },
        },
        fixedHeader: {
            // a flag to keep table header fixed at the top
            type: Boolean,
            default: true,
        },
        fixedFirstCol: {
            // a flag to keep table first row fixed to the left
            type: Boolean,
        },
        defaultSort: {
            type: Object,
            default() {
                return { by: 'created_at', direction: '-' };
            },
        },
        keysLookup: {
            type: Object,
            default() {
                return {};
            },
        },
        relatedFilterChipKeys: {
            type: Object,
            default: () => ({}),
        },
        hasSearch: {
            type: Boolean,
        },
        searchFields: {
            type: Array,
            default: () => [],
        },
        searchHint: {
            type: String,
            default: '',
        },
        excludeFromSelectionCallback: {
            type: Function,
            default() {
                return false;
            },
        },
        hideFilterChips: {
            type: Boolean,
        },
        paddingClasses: {
            type: String,
            default: 'px-6',
        },
        ignoreRouteChanges: {
            type: Function,
            default() {
                return false;
            },
        },
        tableTitle: {
            type: String,
            default: '',
        },
        headerClasses: {
            type: String,
            default: 'py-4',
        },
        helpCenterPage: {
            type: String,
            default: '',
        },
        dataTestIdPrefix: {
            type: String,
            default: '',
        },
        isValidFilters: {
            type: Function,
            default: () => true,
        },
        showInfoText: {
            type: Boolean,
        },
        infiniteScroll: {
            type: Boolean,
        },
    },
    emits: [
        'items-selected',
        'updated-filters',
        'fetching',
        'applied-filters',
        'update:total',
        'fetched',
        'row-clicked-with-ctrl',
        'row-clicked',
    ],
    setup() {
        const { mixpanelTrackEvent } = useMixpanel();

        const http = useHttp();

        return {
            mixpanelTrackEvent,
            http,
        };
    },
    data() {
        return {
            initializing: true,
            filters: {},
            filtersModalForm: {},
            selectedOptionsFromSelectInput: {},
            filtersModalOpened: false,
            currentTab: null,
            items: [],
            selectedItemsIds: [],
            // for caching all selected items details
            selectedItems: [],
            currentPage: 1,
            hasPagination: true,
            isFetching: false,
            querySort: null,
            sort: this.defaultSort,
            responseMeta: null,
            hasFiltersBeenSelected: false,
            searchQuery: '',
            isCursor: false,
            currentCursor: '',
            supplementFilterChips: {},
            requestController: {},
            focusOnError: false,
            responseDetails: null,
        };
    },
    computed: {
        allItemsSelected() {
            return (
                this.selectedItemsIds.length > 0 &&
                this.selectedItemsIds.length ===
                    this.items.filter(
                        (item) => !this.excludeFromSelectionCallback(item)
                    ).length
            );
        },
        canShowPaginationInfo() {
            return (
                this.responseMeta &&
                this.responseMeta.to &&
                this.responseMeta.total
            );
        },
        displayItems() {
            // items that are filtered and sorted and ready to be displayed
            return this.itemsFilter(this.items, this.filters);
        },
        shouldShowFiltersChips() {
            return (
                !this.showTabs &&
                !this.initializing &&
                this.activeFiltersCount() &&
                !this.hideFilterChips
            );
        },

        showTabs() {
            const query = qs.parse(this.$route.query);
            return (
                this.tabs.length > 0 &&
                (!query.filter || Boolean(query.showTabs))
            );
        },
        isCustomerPortal() {
            return this.$root.mainStore.isCustomerPortal;
        },
        isBulkActionsDisabled() {
            return typeof this.disableBulkActions === 'function'
                ? this.disableBulkActions(this.items)
                : this.disableBulkActions;
        },
    },
    watch: {
        selectedItemsIds(val) {
            this.$emit('items-selected', {
                itemsIds: clone(val),
                selectedItems: clone(this.selectedItems),
            });
        },
        $route() {
            if (this.ignoreRouteChanges()) {
                return;
            }
            this.init();
        },
        filtersModalForm: {
            deep: true,
            handler(value) {
                this.$emit('updated-filters', value);
            },
        },
    },
    mounted() {
        if (this.title.length && !this.isCustomerPortal) {
            document.title = `${this.$t(this.title)} - ${this.$t(
                'misc.foodics'
            )}`;
        }

        if (!isObjectEmpty(this.filters)) {
            this.hasFiltersBeenSelected = true;
        }

        this.init();

        Bus.$on('select-input:choose-option', (data) => {
            this.selectedOptionsFromSelectInput = {
                ...this.selectedOptionsFromSelectInput,
                ...data,
            };
        });
    },
    beforeUnmount() {
        Bus.$off('select-input:choose-option');
        this.requestController.abort();
    },
    methods: {
        init() {
            const query = parseObject(qs.parse(this.$route.query));
            this.filters = cloneDeep(query.filter || this.hiddenFilters);
            this.filtersModalForm = cloneDeep(this.filters);
            this.selectedOptionsFromSelectInput = query.filterChips ?? {};
            this.searchQuery = query.search ?? '';
            this.supplementFilterChips = query.supplementFilterChips ?? {};

            if (query.page && parseInt(query.page)) {
                this.currentPage = parseInt(query.page);
            } else if (query.page) {
                this.currentCursor = query.page;
            } else {
                this.currentPage = 1;
            }

            if (query.sort) {
                this.sort = {
                    by: query.sort,
                    direction: query.direction,
                };
            } else {
                this.sort = { ...this.defaultSort };
            }

            this.querySort = this.sort.direction + this.sort.by;

            this.selectedItemsIds = [];
            this.selectedItems = [];
            this.changeCurrentTab();

            if (this.infiniteScroll) {
                this.items = [];
            }

            if (this.filtersRequired && !this.hasFiltersBeenSelected) {
                this.filtersModalOpened = true;
                this.initializing = false;
            } else {
                this.fetchItems();
            }
        },
        fetchItems() {
            NProgress.start();
            this.isFetching = true;

            this.requestController = new AbortController();
            const signal = this.requestController.signal;
            const filters = { ...this.defaultFilters, ...(this.filters || {}) };

            this.$emit('fetching', { payload: true });
            this.$emit('applied-filters', this.filters);

            const requestBody = {
                filter: filters,
                page: this.currentCursor
                    ? this.currentCursor
                    : this.currentPage,
                include: this.requestIncludes,
                sort: this.querySort,
                ...(this.searchQuery && { search: this.searchQuery }),
                ...this.supplementRequestData,
            };
            const request =
                this.requestType === 'post'
                    ? this.http.post(this.requestUrl, requestBody)
                    : this.http.get(
                          `${this.requestUrl}?${qs.stringify(requestBody)}`,
                          { signal }
                      );

            Promise.resolve(request)
                .then((response) => {
                    this.$emit('fetching', { payload: false });
                    this.responseMeta = response.data.meta;
                    this.responseDetails = response?.data;

                    this.isCursor = Boolean(response.data.is_cursor);
                    const totalCount = response.data?.meta?.total;
                    if (this.isCursor) {
                        this.$emit(
                            'update:total',
                            response.data.data.length ? Infinity : 0
                        );
                    }
                    if (totalCount !== undefined && totalCount !== null) {
                        this.$emit('update:total', response.data.meta.total);
                    }
                    if (this.infiniteScroll) {
                        this.items.push(
                            ...(this.dataFilter
                                ? this.dataFilter(response.data.data)
                                : response.data.data)
                        );
                    } else {
                        this.items = this.dataFilter
                            ? this.dataFilter(response.data.data)
                            : response.data.data;

                        this.currentPage = response.data.meta
                            ? parseInt(response.data.meta.current_page)
                            : 1;
                    }

                    this.$emit('fetched', { data: cloneDeep(this.items) });
                    this.hasPagination = Boolean(response.data.meta);
                })
                .catch((error) => {
                    if (this.errorCallback) {
                        this.errorCallback(error);
                    }
                })
                .finally(() => {
                    this.isFetching = false;
                    this.initializing = false;
                    NProgress.done();
                });
        },
        changeCurrentTab() {
            const current = find(this.tabs, (tab) => {
                return isEqual(tab.filters, this.filters);
            });

            this.currentTab = current ? current.filters : null;
        },
        activeFiltersCount() {
            const filters = omitBy(this.filters, (v) => v === '');

            return Object.keys(filters).length;
        },
        async applyFilters(showTabs = false) {
            const filters = await this.externalFiltersTransform(
                clone(this.filters)
            );

            this.resetPagination();
            //  this is used for filter-chips component to get the selected Labels
            // use flatten for multi-select values
            this.selectedOptionsFromSelectInput = isEmpty(filters)
                ? {}
                : pick(
                      this.selectedOptionsFromSelectInput,
                      flatten(Object.values(filters))
                  );

            const queryParams = {
                filter: filters,
                filterChips: { ...this.selectedOptionsFromSelectInput },
                page: this.currentPage,
                showTabs: showTabs ? true : null,
            };

            if (this.isCustomerPortal && !showTabs) {
                this.mixpanelTrackFilters(filters);
            }
            this.updateURL(queryParams);
        },
        mixpanelTrackFilters(filters) {
            const dimensions = Object.keys(filters);
            this.mixpanelTrackEvent('CP Filter', {
                dimensions,
            });
        },
        mixpanelTrackSoftFilters(tab) {
            const englishSoftFilterName = this.$t(tab.title, 'en');
            this.mixpanelTrackEvent('CP Soft Filter', {
                softFilter: englishSoftFilterName,
            });
        },
        submitFiltersModalForm() {
            this.hasFiltersBeenSelected = true;
            this.filters = cloneDeep(
                this.filtersCallback(cloneDeep(this.filtersModalForm))
            );
            this.closeFiltersModal();
            this.applyFilters();
        },
        closeFiltersModal() {
            this.filtersModalOpened = false;
            // Reset the filters modal form to the current filters as sometimes
            // filtersModalForm updated but not applied because of the validation
            this.filtersModalForm = cloneDeep(this.filters);
            this.focusOnError = false;
        },
        switchTabs(tab, isForced = false) {
            if (tab.filters === null) {
                this.filters = {};
            }
            if (this.isCurrentTab(tab) && tab.filters !== null) {
                return;
            }
            if (!isForced) {
                this.filters = {};
            }

            forOwn(tab.filters, (value, key) => {
                this.filters[key] = value;
            });
            if (this.isCustomerPortal) {
                this.mixpanelTrackSoftFilters(tab);
            }
            this.filtersModalForm = clone(this.filtersCallback(this.filters));
            this.applyFilters(true);
        },
        isCurrentTab(tab) {
            return this.currentTab === tab?.filters;
        },
        resetPagination() {
            this.currentPage = 1;
            this.currentCursor = '';
        },
        previousPage() {
            if (!this.isCursor) {
                this.currentPage = this.currentPage - 1;
            }

            this.changeRouteForPagination('prev');
        },
        nextPage() {
            if (
                this.infiniteScroll &&
                this.responseMeta?.last_page >= this.currentPage
            ) {
                this.currentPage++;
                this.fetchItems();
            } else if (!this.infiniteScroll) {
                if (!this.isCursor) {
                    this.currentPage += 1;
                }
                this.changeRouteForPagination('next');
            }
        },
        changeRouteForPagination(direction) {
            const directionCursorMap = {
                next: this.responseMeta?.next_cursor,
                prev: this.responseMeta?.prev_cursor,
            };

            this.updateURL({
                page: this.isCursor
                    ? directionCursorMap[direction]
                    : this.currentPage,
            });

            this.$refs.tableContent.scrollTop = 0;
        },
        toggleItemSelection(item) {
            if (this.itemIsSelected(item)) {
                this.selectedItems = this.selectedItems.filter(
                    (selectedItem) => selectedItem.id !== item.id
                );
                return (this.selectedItemsIds = pull(
                    clone(this.selectedItemsIds),
                    item.id
                ));
            }

            this.selectedItems = [...this.selectedItems, item];
            this.selectedItemsIds = [...this.selectedItemsIds, item.id];
        },
        itemIsSelected(item) {
            return includes(this.selectedItemsIds, item.id);
        },
        toggleAllItemsSelection() {
            if (this.allItemsSelected) {
                this.selectedItemsIds = [];
                this.selectedItems = [];
            } else {
                const finalSelectedItems = this.items.filter(
                    (item) => !this.excludeFromSelectionCallback(item)
                );
                this.selectedItemsIds = map(finalSelectedItems, 'id');
                this.selectedItems = clone(finalSelectedItems);
            }
        },
        handleRowClick(item, withCtrl = false) {
            if (!this.isRowClickable || window.getSelection().toString()) {
                return;
            }

            this.$root.mainStore.cachePrevFullPath();

            if (withCtrl) {
                this.$emit('row-clicked-with-ctrl', item);
            } else {
                this.$emit('row-clicked', item);
            }
        },
        submitButtonClicked() {
            if (!this.isValidFilters(this.filtersModalForm)) {
                this.focusOnError = true;
                this.$refs.filterInfoRef.scrollIntoView({
                    behavior: 'smooth',
                });
                return;
            }
            this.filtersModalForm = omitBy(
                this.filtersModalForm,
                (v) => isNull(v) || isUndefined(v) || v === ''
            );
            if (
                qs.stringify(this.filtersModalForm) ===
                qs.stringify(this.filters)
            ) {
                this.closeFiltersModal();
                return;
            }
            this.submitFiltersModalForm();
        },
        updateSortBy(column) {
            if (this.sort.by === column) {
                this.sort.direction = this.sort.direction === '' ? '-' : '';
                if (!this.sort.direction) {
                    this.sort.by = '';
                }
            } else {
                this.sort = { by: column, direction: '' };
            }

            this.updateURL({
                sort: this.sort.by,
                direction: this.sort.direction,
            });
        },
        clearFilter(keys) {
            this.filtersModalForm = omit(this.filtersModalForm, keys);
            this.submitFiltersModalForm();
        },
        clearAllFilters() {
            if (this.showTabs) {
                return;
            }
            this.filtersModalForm = this.clearFiltersCallback(this.filters);
            this.submitFiltersModalForm();
        },
        searchClick(query) {
            if (this.searchQuery === query) {
                return;
            }

            this.searchQuery = query;
            this.resetPagination();

            this.updateURL({
                search: query,
                page: this.currentPage,
            });
        },
        refreshItems() {
            this.selectedItemsIds = [];
            this.selectedItems = [];

            this.fetchItems();
        },
        overwriteFilters(data) {
            return (this.filtersModalForm = { ...data });
        },
        updateURL(queryParams = {}) {
            const currentQueryParams = qs.parse(this.$route.query) ?? {};
            const finalQueryParams = qs.stringify(
                {
                    ...currentQueryParams,
                    ...queryParams,
                },
                { skipNulls: true }
            );
            this.$router.push(
                `${this.$route.path}?${finalQueryParams}${
                    this.$route.hash || ''
                }`
            );
        },
        roundDownToNearestThousand,
        camelCase,
    },
};
</script>

<template>
    <div
        ref="tableContent"
        class="screen-listing mx-auto"
        :class="[
            {
                'fixed-table': fixedHeader,
                'fixed-first-col': fixedFirstCol,
                'has-bulk-actions': !isBulkActionsDisabled,
            },
            paddingClasses,
        ]">
        <decorated-modal
            v-if="filtersModalOpened"
            close-button
            :title="$t('button_label.filter')"
            :width-class="'md:max-w-[600px]'"
            body-container-class="filter-modal-body"
            drawer
            @close="closeFiltersModal">
            <template #body>
                <div
                    v-if="showInfoText"
                    ref="filterInfoRef"
                    :class="[
                        'bg-blue-100 border border-blue-100 rounded px-4 py-3 mb-4',
                        {
                            '!border-primary': focusOnError,
                        },
                    ]">
                    <slot name="filtersModalInfo" />
                </div>
                <slot name="filtersModalContent" :filters="filtersModalForm" />
            </template>

            <template #footer>
                <button
                    v-if="!showTabs"
                    class="text-gray-700 hover:underline me-auto"
                    :data-testid="camelCase(`${dataTestIdPrefix}ClearButton`)"
                    @click="clearAllFilters">
                    {{ $t('button_label.clear') }}
                </button>
            </template>

            <template #footerRight>
                <button
                    :data-testid="camelCase(`${dataTestIdPrefix}ApplyButton`)"
                    class="btn btn-primary ms-2"
                    @click="submitButtonClicked">
                    {{ $t('button_label.apply') }}
                </button>
            </template>
        </decorated-modal>

        <div
            v-if="showHeader"
            id="table_header"
            data-testid="tableHeader"
            class="flex items-center">
            <div class="w-full">
                <div>
                    <div>
                        <breadcrumb
                            v-if="breadcrumbHref"
                            :title="$t('button_label.back')"
                            :href="breadcrumbHref"
                            :force-href="forceBreadcrumbHref" />
                        <div :class="headerClasses">
                            <div
                                class="flex flex-wrap flex-row justify-between items-start gap-5">
                                <div>
                                    <div class="flex gap-x-5">
                                        <h1
                                            class="text-title-1 md:text-3xl text-gray-1000">
                                            {{ $t(title) }}
                                        </h1>
                                        <search
                                            v-if="hasSearch"
                                            :search-query
                                            :search-hint
                                            :search-fields
                                            @on-search="searchClick" />
                                    </div>
                                    <help-center-link
                                        v-if="helpCenterPage"
                                        :page="helpCenterPage"
                                        class="mt-2" />
                                </div>

                                <div
                                    :class="[
                                        'flex justify-end',
                                        {
                                            'w-auto md:w-full xl:w-auto':
                                                hasSearch,
                                        },
                                    ]">
                                    <slot name="tableHeaderActions" />
                                </div>
                            </div>

                            <div
                                v-if="$slots.tableHeaderNote"
                                class="flex mt-4">
                                <slot name="tableHeaderNote" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <filters-chips
            v-if="shouldShowFiltersChips"
            :filters
            :keys-lookup
            :related-filter-chip-keys
            :supplement-filter-chips
            :selected-options-from-select-input
            @clear-filter="clearFilter"
            @clear-all-filters="clearAllFilters" />

        <div v-if="initializing">
            <screen-loader />
        </div>
        <div v-if="!initializing">
            <div
                v-if="
                    listingStyle === 'table' &&
                    ($slots.subheader || $slots.subheader)
                ">
                <slot name="subheader" :items />
            </div>

            <div
                v-if="listingStyle === 'table'"
                :class="`${tableClasses} space-y-4`">
                <div
                    v-if="showTabs || showRefreshButton || hasFilters"
                    class="card-header flex align-center gap-3 items-end w-full"
                    :class="[showTabs ? 'justify-between' : 'justify-end']">
                    <div v-if="showTabs" class="flex-grow-1 overflow-x-auto">
                        <fdx-scroll-fade>
                            <button
                                v-for="(tab, i) in tabs"
                                :key="i"
                                :data-text="$t(tab.title)"
                                class="flex flex-shrink-0 whitespace-nowrap px-3 !pb-2 border-b-2 border-transparent"
                                :class="{
                                    'font-bold text-gray-1000 !border-primary':
                                        isCurrentTab(tab),
                                }"
                                @click.stop.prevent="switchTabs(tab)">
                                {{ $t(tab.title) }}
                            </button>
                        </fdx-scroll-fade>
                    </div>

                    <div
                        v-else-if="tableTitle"
                        class="tabs-container flex w-full overflow-x-auto md:overflow-hidden text-2xl text-gray-700 font-normal">
                        {{ $t(tableTitle) }}
                    </div>
                    <div class="flex align-center justify-end flex-shrink-0">
                        <button
                            v-if="showRefreshButton"
                            :disabled="isFetching"
                            data-testid="refreshItemsButton"
                            class="btn btn-white hidden md:flex"
                            @click="fetchItems()">
                            <svg-icon
                                v-if="isFetching"
                                class="w-6 stroke-primary"
                                icon="loader" />
                            <svg-icon
                                v-else
                                class="size-6 fill-[#000]"
                                icon="reload" />
                        </button>
                        <button
                            v-if="hasFilters && isFilterInHeader"
                            :data-testid="
                                camelCase(`${dataTestIdPrefix}FilterButton`)
                            "
                            class="btn btn-white ms-2 px-8 py-2 items-center inline-flex shrink-0"
                            @click="filtersModalOpened = true">
                            <svg-icon icon="filter-icon" />

                            <span class="hidden md:flex md:ms-2">
                                {{ $t('button_label.filter') }}
                            </span>
                            <template v-if="activeFiltersCount()">
                                <span
                                    class="flex justify-center items-center size-6 text-xs text-white rounded-full bg-gray-500 ms-2 shrink-0">
                                    {{ activeFiltersCount() }}
                                </span>
                                <span v-if="!showTabs" class="hidden ms-2">
                                    <button
                                        class="flex"
                                        data-testid="clearFilterButton"
                                        @click.stop="clearAllFilters">
                                        <svg-icon
                                            class="size-6 fill-gray-500"
                                            icon="cross-in-circle" />
                                    </button>
                                </span>
                            </template>
                        </button>
                        <div class="ms-2 flex gap-2">
                            <slot name="headerRight"></slot>
                        </div>
                    </div>
                </div>
                <div class="card-body !mt-0">
                    <div
                        v-if="!displayItems.length"
                        class="p-8 text-center font-light rounded-lg bg-white">
                        <span v-if="filtersRequired && !hasFiltersBeenSelected">
                            {{ $t('Please select some filters first.') }}
                        </span>
                        <screen-loader v-else-if="isFetching" />
                        <span
                            v-else
                            class="block mx-auto max-w-4xl error-message">
                            {{ $t(noRecordsText) }}
                        </span>
                    </div>

                    <div v-if="displayItems.length">
                        <div
                            v-if="
                                selectedItemsIds.length &&
                                !isBulkActionsDisabled
                            "
                            class="bulk-actions-bar ps-4 my-2 flex items-center rounded-lg">
                            <span class="font-semibold">
                                {{
                                    `${selectedItemsIds.length} ${$t(
                                        'listing_screen.selected'
                                    )}`
                                }}
                            </span>

                            <drop-down
                                v-if="$slots.selectedItemsActions"
                                class="relative ms-5"
                                dropdown-content-classes="p-4">
                                <template #trigger>
                                    <button
                                        type="button"
                                        class="btn btn-white flex items-center">
                                        <span class="me-1">
                                            {{ $t('actions') }}
                                        </span>
                                        <svg-icon
                                            class="w-2 h-2 ms-2"
                                            icon="arrow-down" />
                                    </button>
                                </template>
                                <template #content>
                                    <slot name="selectedItemsActions" />
                                </template>
                            </drop-down>
                        </div>

                        <template v-if="!isFetching">
                            <slot name="beforeTable" />
                        </template>

                        <fdx-scroll-fade v-if="displayItems.length">
                            <div
                                ref="tableContent"
                                scroll-container-ref="listing-table"
                                :class="[
                                    'table-wrapper rounded-b-lg w-full',
                                    {
                                        'rounded-lg':
                                            tabs.length === 0 &&
                                            !showRefreshButton &&
                                            !hasFilters,
                                    },
                                    isCustomerPortal
                                        ? 'table-wrapper-cp'
                                        : 'table-wrapper-mms',
                                ]">
                                <table
                                    id="listing-table"
                                    data-testid="listingTable"
                                    class="card-table w-full">
                                    <thead
                                        :class="{ 'fixed-thead': fixedHeader }">
                                        <tr
                                            :class="{
                                                'whitespace-nowrap': noWrap,
                                            }">
                                            <th
                                                v-if="!isBulkActionsDisabled"
                                                class="flex">
                                                <checkbox
                                                    :selected="allItemsSelected"
                                                    @click="
                                                        toggleAllItemsSelection
                                                    " />
                                            </th>
                                            <template
                                                v-for="(column, i) in columns"
                                                :key="i">
                                                <th
                                                    class="px-4 py-2 items-center space-x-1"
                                                    :class="{
                                                        'cursor-pointer':
                                                            column.sortable,
                                                    }"
                                                    @click="
                                                        column.sortable
                                                            ? updateSortBy(
                                                                  column.name
                                                              )
                                                            : null
                                                    ">
                                                    <div
                                                        class="flex items-center">
                                                        <span
                                                            class="text-body font-bold"
                                                            >{{
                                                                $t(column.label)
                                                            }}</span
                                                        >

                                                        <span
                                                            v-if="
                                                                column.sortable
                                                            "
                                                            class="flex items-center ml-1">
                                                            <svg-icon
                                                                v-if="
                                                                    sort.by ===
                                                                    column.name
                                                                "
                                                                icon="sort-up"
                                                                :class="[
                                                                    'size-4',
                                                                    {
                                                                        'rotate-180':
                                                                            sort.direction ===
                                                                            '-',
                                                                    },
                                                                ]" />
                                                            <svg-icon
                                                                v-else
                                                                icon="unfold_more"
                                                                class="size-4" />
                                                        </span>
                                                    </div>
                                                </th>
                                            </template>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item, i) in displayItems"
                                            :key="i"
                                            :class="{
                                                'cursor-pointer':
                                                    isRowClickable,
                                                'whitespace-nowrap': noWrap,
                                            }"
                                            @click.exact="handleRowClick(item)"
                                            @click.ctrl="
                                                handleRowClick(item, true)
                                            "
                                            @click.meta="
                                                handleRowClick(item, true)
                                            ">
                                            <td
                                                v-if="
                                                    !isBulkActionsDisabled &&
                                                    !excludeFromSelectionCallback(
                                                        item
                                                    )
                                                "
                                                class="flex"
                                                @click.stop>
                                                <checkbox
                                                    :selected="
                                                        itemIsSelected(item)
                                                    "
                                                    @click="
                                                        toggleItemSelection(
                                                            item
                                                        )
                                                    " />
                                            </td>
                                            <td
                                                v-else-if="
                                                    !isBulkActionsDisabled &&
                                                    excludeFromSelectionCallback(
                                                        item
                                                    )
                                                "></td>
                                            <slot name="tableRow" :item>
                                                <td
                                                    v-for="key in Object.keys(
                                                        item
                                                    )"
                                                    :key>
                                                    {{ item[key] }}
                                                </td>
                                            </slot>
                                        </tr>
                                    </tbody>
                                    <tfoot v-if="$slots.tableFooter">
                                        <tr
                                            class="table-footer border-t border-gray-300">
                                            <slot
                                                name="tableFooter"
                                                :items="displayItems"
                                                :response-details>
                                            </slot>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div class="infinite-scroll-trigger">
                                    <Fdx-intersection-trigger
                                        v-if="
                                            infiniteScroll &&
                                            !isFetching &&
                                            items.length >= (perPage || 50)
                                        "
                                        @intersected="nextPage" />
                                </div>
                            </div>
                        </fdx-scroll-fade>
                    </div>
                    <div
                        v-if="
                            hasPagination &&
                            displayItems.length &&
                            !infiniteScroll
                        "
                        id="table_pagination"
                        data-testid="tablePagination">
                        <div
                            class="md:flex pt-4 rounded-b border-t border-gray-300 text-footnote text-gray-800"
                            :class="[
                                responseMeta.total ||
                                responseMeta.from ||
                                responseMeta.to
                                    ? 'md:justify-between'
                                    : 'md:justify-end',
                            ]">
                            <div v-if="canShowPaginationInfo">
                                {{
                                    `${$t('listing_screen.showing')} ${
                                        responseMeta.from
                                    } ${$t('listing_screen.to')} ${
                                        responseMeta.to
                                    } ${$t('listing_screen.out_of')} ${
                                        responseMeta.total
                                    }`
                                }}
                            </div>
                            <div v-else-if="isCursor && responseMeta.total">
                                {{
                                    `${$t(
                                        'listing_screen.total'
                                    )} ${roundDownToNearestThousand(
                                        responseMeta.total
                                    )}`
                                }}
                            </div>
                            <div
                                v-if="
                                    perPage === -1 ||
                                    !(
                                        currentPage === 1 &&
                                        items.length < perPage
                                    )
                                "
                                class="flex justify-between"
                                :class="
                                    canShowPaginationInfo || isCursor
                                        ? ''
                                        : 'flex-col md:flex md:justify-between md:w-full'
                                ">
                                <button
                                    class="text-primary font-bold me-2"
                                    :disabled="
                                        currentPage == 1 ||
                                        (isCursor && !responseMeta.prev_cursor)
                                    "
                                    @click="previousPage">
                                    {{ $t('button_label.previous') }}
                                </button>
                                <button
                                    class="text-primary font-bold"
                                    :disabled="
                                        items.length === 0 ||
                                        (perPage !== -1 &&
                                            responseMeta.last_page <=
                                                currentPage) ||
                                        (isCursor && !responseMeta.next_cursor)
                                    "
                                    @click="nextPage">
                                    {{ $t('button_label.next') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.bulk-actions-bar {
    height: 56px;
    z-index: 20;
}

.rtl .bulk-actions-bar {
    right: 54px;
}

.ltr .bulk-actions-bar {
    left: 54px;
}

.rtl .card-header a {
    /* This is to fix the arabic font that gets clipped in listing screen */
    display: inline-block;
}

.card-header a {
    padding-bottom: 0;
}

.fixed-table thead,
.fixed-table tfoot,
.fixed-first-col tr td:first-child,
.fixed-first-col th:first-child,
.fixed-first-col.has-bulk-actions tr td:nth-child(2),
.fixed-first-col.has-bulk-actions th:nth-child(2) {
    position: sticky;
    background-color: rgb(247, 250, 252);
}
.fixed-table thead.fixed-thead {
    top: 0;
    z-index: 10;
}
.fixed-first-col tr:hover td:first-child,
.fixed-first-col tr:hover th:first-child,
.fixed-first-col tr:hover td:nth-child(2),
.fixed-first-col tr:hover th:nth-child(2) {
    background-color: rgb(247, 250, 252) !important;
}
.fixed-table tfoot {
    bottom: 0;
    z-index: 10;
}
/* should be refactored with the accurate numbers  */
.fixed-table .table-wrapper-cp {
    max-height: calc(100vh - 24rem);
}

.fixed-table .table-wrapper-mms {
    max-height: calc(100vh - 21rem);
}

#listing-table thead tr {
    position: sticky;
    top: 0;
    z-index: 1;
}

.tabs-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.tabs-container::-webkit-scrollbar {
    display: none;
}

.infinite-scroll-trigger {
    position: relative;
    height: 1px;
    margin-top: -1px;
}
</style>
