import { isEqual, omitBy, isUndefined, isNull } from 'lodash';

export function removeEmptyProperties(obj) {
    return Object.entries(obj).reduce(
        (a, [k, v]) => (v === null || v === undefined ? a : ((a[k] = v), a)),
        {}
    );
}

/**
 * Compares two objects and returns an array of keys that have different values at the top level
 *
 * @param {Object} obj1 - First object to compare
 * @param {Object} obj2 - Second object to compare
 * @param {Object} [opts={}] - Options object
 * @param {boolean} [opts.emptyStringEqualNull=false] - If true, treats empty strings and null values as equal
 * @returns {string[]} Array of keys that have different values between the two objects
 *
 * @example
 * const obj1 = { a: 1, b: 2, c: null };
 * const obj2 = { a: 1, b: 3, c: '' };
 * getTopLevelDiffKeys(obj1, obj2); // ['b', 'c']
 * getTopLevelDiffKeys(obj1, obj2, { emptyStringEqualNull: true }); // ['b']
 */
export function getTopLevelDiffKeys(obj1, obj2, opts = {}) {
    if (!obj1) {
        obj1 = {};
    }

    return Object.keys(obj1).reduce((result, key) => {
        //@doc the use of Object.prototype.hasOwnProperty.call is to avoid the case where the Object.hasOwn is not supported
        if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
            result.push(key);
        } else {
            const { emptyStringEqualNull = false } = opts;

            let val1 = obj1[key];
            let val2 = obj2[key];

            if (emptyStringEqualNull) {
                if (val1 === null) {
                    val1 = '';
                }
                if (val2 === null) {
                    val2 = '';
                }
            }

            if (isEqual(val1, val2)) {
                const resultKeyIndex = result.indexOf(key);
                result.splice(resultKeyIndex, 1);
            }
        }
        return result;
    }, Object.keys(obj2));
}

export function removeAllEmptyValues(obj) {
    return omitBy(
        obj,
        (v) =>
            isUndefined(v) ||
            isNull(v) ||
            v === '' ||
            (v &&
                v instanceof Object &&
                !Array.isArray(v) &&
                Object.keys(v).length === 0)
    );
}

export function isObject(obj) {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

export function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}
