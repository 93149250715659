import { mixpanelTrackEvent } from '@/common/Composables/useMixpanel.js';

export default {
    computed: {
        // dummy auth to prevent errors in components that check for authorities
        auth() {
            return {
                hasAuthority: () => {
                    return true;
                },
            };
        },
    },
    methods: {
        mixpanelErrorEvent(response) {
            const config = response?.config || {};
            const { url = '', method = '' } = config;
            const [baseUrl, query = ''] = url.split('?');
            mixpanelTrackEvent(
                'CP API Error',
                {
                    code: response?.status || '',
                    errorDescription: response?.data?.message || '',
                    requestType: method,
                    fullRequestUrl: url,
                    requestUrl: baseUrl,
                    requestQuery: query,
                    errorCode: response?.headers?.error_code || '',
                },
                this.$route
            );
        },
    },
};
